import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import moment from 'moment';
const DepositWithdrawalChart = ({ allTransactionsList }) => {
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: 'Total Deposits',
        data: [],
        borderColor: '#00AD3C',
        backgroundColor: 'rgba(0, 173, 60, 0.2)',
        fill: true,
      },
      {
        label: 'Total Withdrawals',
        data: [],
        borderColor: '#FF5733',
        backgroundColor: 'rgba(255, 87, 51, 0.2)',
        fill: true,
      },
    ],
  });

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const labels = Array.from({ length: 5 }, (_, i) => `${currentYear - 4 + i}`);
    const depositData = Array.from({ length: 5 }, () => 0);
    const withdrawalData = Array.from({ length: 5 }, () => 0);

    allTransactionsList?.forEach((transaction) => {
      const { date } = transaction;
      const seconds = date?.seconds;
      const nanoseconds = date?.nanoseconds;
      const milliseconds = seconds * 1000 + Math.floor(nanoseconds / 1000000);
      const dates = moment(milliseconds);
      const year = dates.year();

      if (!isNaN(year)) {
        const yearIndex = labels.indexOf(`${year}`);

        if (yearIndex !== -1) {
          if (transaction?.request_type === 0) {
            depositData[yearIndex] += transaction?.total_amount;
          } else {
            withdrawalData[yearIndex] += transaction?.withdraw_amount;
          }
        }
      }
    });

    setChartData({
      labels,
      datasets: [
        {
          label: 'Total Deposits',
          data: depositData,
          borderColor: '#00AD3C',
          backgroundColor: 'rgba(0, 173, 60, 0.2)',
          fill: true,
        },
        {
          label: 'Total Withdrawals',
          data: withdrawalData,
          borderColor: '#FF5733',
          backgroundColor: 'rgba(255, 87, 51, 0.2)',
          fill: true,
        },
      ],
    });
  }, [allTransactionsList]);

  return (
    <div style={{ width: '100%', height: '400px' }}>
      <Line data={chartData} options={{
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          title: {
            display: true,
           
          },
          legend: {
            position: 'top',
          },
        },
      }} />
    </div>
  );
};

export default DepositWithdrawalChart;
