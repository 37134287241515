import { Box, Button, Dialog, Grid, IconButton, Typography } from '@mui/material';
import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useFormik } from 'formik';
import * as Yup from "yup";
import moment from "moment";
import { toast } from 'react-toastify';
import CustomTextField from '../../../../Components/CustomTextField/CustomTextField';
import CustomSubmitButton from '../../../../Components/CustomSubmitButton/CustomSubmitButton';
import EditIcon from "@mui/icons-material/Edit";
import Skeleton from 'react-loading-skeleton';
import DOCUMENT_ICON from '../../../../Assets/Images/Common/documentation.png';
import UPLOAD_GALLERY_ICON from "../../../../Assets/Images/Common/gallery.png";
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { DATABASE, STORAGE } from '../../../../Config/firebase';
import { doc, setDoc, Timestamp, updateDoc } from 'firebase/firestore';
const NewDepositModel = (props) => {
    const { open, onClose, typeCheck, handleOnClose, customerDeposit, handleSubmited } = props;

    const [isLoading, setIsLoading] = useState(false);
    const [mediaTypes, setMediaTypes] = useState(null);
    const [mediaUploads, setMediaUploads] = useState(null);
    const [mediaPreview, setMediaPreview] = useState(null);
    const getTimestamp = () => {
        const currentDate = moment.utc(); // Get current time in UTC
        const hour = currentDate.hour(); // Get the hour in UTC
      
        if (hour >= 21) {
          // If time is 9 PM UTC or later, add one day and keep the time
          currentDate.add(1, "day");
        }
        return currentDate.toDate(); // Return the current or next day timestamp in UTC
      };
    const handleUploadMedia = (e) => {
        const file = e.target.files[0];
        if (file) {
            if (file.size <= 5 * 1024 * 1024) {
                const fileType = file.type.split('/')[0];
                const acceptedDocumentTypes = [
                    'application/pdf',
                    'application/msword',
                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                ];
                if (fileType === 'image') {
                    setMediaUploads(file);
                    setMediaTypes('image');
                    setMediaPreview(URL.createObjectURL(file));
                    formik.setFieldValue('receipt', file);
                } else if (acceptedDocumentTypes.includes(file.type)) {
                    setMediaUploads(file);
                    setMediaTypes('document');
                    setMediaPreview(null);
                    formik.setFieldValue('receipt', file);
                } else {
                    toast.error(`Invalid file type. Please upload an image or a document.`);
                }
            } else {
                toast.error(`File size exceeds the limit of 5MB`);
            }
        }
    };
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            amount: "",
            receipt: null,
            notes: "",
            selectCustomerId: 0
        },
        validationSchema: Yup.object().shape({
            amount: Yup.string().required("Amount is required"),
            receipt: Yup.string().required("Deposit receipt is required"),
            notes: Yup.string().required("Notes are required"),
        }),
        onSubmit: async (values, { resetForm }) => {
            setIsLoading(true);
            try {
                if (isLoading) return;
                let imageDownloadURL = null;
                if (mediaUploads) {
                    const imageRef = ref(STORAGE, `images/profile/${Date.now()}`);
                    await uploadBytes(imageRef, values.receipt);
                    imageDownloadURL = await getDownloadURL(imageRef);
                }

                const newAmount = parseFloat(values.amount);
                const currentNetBalance = customerDeposit[0]?.net_balance || 0;
                const ledgersDocId = Date.now().toString()
                const transactionData = {
                    admin_commission: 0,
                    date: Timestamp.now(),
                    request_status: 2,
                    request_type: 0,
                    notes: values.notes,
                    payment_receipt: imageDownloadURL,
                    user_id: customerDeposit[0]?.doc_id,
                    remaining_balance: 0,
                    profit_loss: 0,
                    total_amount: newAmount,
                    profit_loss_percent: 0,
                    request_approval_time: Timestamp.now(),
                    transection_fee: 0,
                    txn_id: ledgersDocId,
                    otp: "0",
                    otp_verify: true,
                    withdraw_amount: 0,
                    doc_id: ledgersDocId,
                };
                const ledgers = {
                    new_txn_amount: newAmount,
                    previous_balance: customerDeposit[0]?.net_balance,
                    current_balance: currentNetBalance + newAmount,
                    date: getTimestamp(),
                    doc_id: Date.now().toString(),
                    transection_type: 0,
                    user_id: customerDeposit[0]?.doc_id,
                    profit_loss_percent: 0,
                    profit_loss_balance: 0,
                }
                const updateNet_Balance = {
                    net_balance: currentNetBalance + newAmount
                }
                await Promise.all([
                    setDoc(doc(DATABASE, "transection_requests", Date.now().toString()), transactionData),
                    updateDoc(doc(DATABASE, "users", customerDeposit[0]?.doc_id), updateNet_Balance),
                    setDoc(doc(DATABASE, "ledgers", ledgers.doc_id), ledgers),
                ]);
                toast.success("Deposit Added Successfully");
                resetForm();
                handleSubmited();
                handleOnClose();
                setMediaPreview(null);
                setMediaTypes(null);
                setMediaUploads(null);

            } catch (error) {
                console.error('Error:', error);
                toast.error("An error occurred. Please try again.");
            } finally {
                setIsLoading(false);
            }
        }
    });

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="xs"
            fullWidth
            sx={{
                "& .MuiPaper-root": {
                    borderRadius: "20px",
                    padding: '20px 30px',
                    height: "600px",
                    overflow: "scroll",
                    "::-webkit-scrollbar": { width: "0px", height: '0px' }
                },
                "& .MuiDialogContent-root": {
                    overflowY: "initial",
                },
                "& .MuiBackdrop-root": {
                    backgroundColor: 'rgba(0, 0, 0, 0.8)',
                }
            }}
        >
            <form onSubmit={(e) => {
                e.preventDefault();
                formik.handleSubmit();
                return false;
            }}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Typography sx={{ fontSize: '20px', fontWeight: '500', color: '#101010', fontFamily: "Outfit" }}>{typeCheck ? "Update" : "New"} Deposit</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end'
                        }}>
                            <IconButton sx={{
                                width: '30px',
                                height: '30px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                backgroundColor: '#101010',
                                borderRadius: '8px',
                                "&:hover": {
                                    backgroundColor: '#101010',
                                    color: '#fff',
                                }
                            }} onClick={handleOnClose}>
                                <CloseIcon sx={{ color: '#fff', fontSize: '20px' }} />
                            </IconButton>
                        </Box>
                    </Grid>
                </Grid>
                <Box sx={{
                    padding: '20px 0px 0px 0px',
                }}>
                    <Grid spacing={2} container>
                        <Grid item xs={12}>
                            <CustomTextField
                                label="Amount"
                                type="number"
                                min="0"
                                max={1}
                                inputMode="numeric"
                                pattern="[0-9]*"
                                onKeyDown={(evt) =>
                                    ["e", "E", "+", "-"].includes(evt.key) &&
                                    evt.preventDefault()
                                }
                                placeholder="Enter Amount"
                                name="amount"
                                value={formik.values.amount}
                                onBlur={formik.handleBlur}
                                onKeyUp={formik.handleBlur}
                                errors={formik.touched.amount && formik.errors.amount}
                                onChange={(e) => {
                                    const { value } = e.target;
                                    if (value.trimStart() !== value) {
                                        e.target.value = value.trimStart();
                                    }
                                    formik.handleChange(e);
                                }}
                                sx={{
                                    "& .MuiInputBase-root": {
                                        borderRadius: "10px",
                                        border: "1px solid #CACACA",
                                        color: "black",
                                        fontSize: "18px",
                                        background: "white",
                                        fontFamily: "Outfit",
                                    },
                                }}
                                style={{ color: "black", fontSize: "18px" }}
                                isLoading={isLoading}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <CustomTextField
                                label="Notes"
                                type="text"
                                placeholder="Enter your notes"
                                name="notes"
                                value={formik.values.notes}
                                onBlur={formik.handleBlur}
                                onKeyUp={formik.handleBlur}
                                multiline={true}
                                rows={3}
                                errors={formik.touched.notes && formik.errors.notes}
                                onChange={(e) => {
                                    const { value } = e.target;
                                    if (value.trimStart() !== value) {
                                        e.target.value = value.trimStart();
                                    }
                                    formik.handleChange(e);
                                }}
                                sx={{
                                    "& .MuiInputBase-root": {
                                        borderRadius: "10px",
                                        border: "1px solid #CACACA",
                                        color: "black",
                                        fontSize: "18px",
                                        background: "white",
                                        fontFamily: "Outfit",
                                    },
                                }}
                                style={{ color: "black", fontSize: "18px" }}
                                isLoading={isLoading}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Box
                                sx={{
                                    width: { xs: "100%", md: "100%", lg: "400px" },
                                    display: "flex",
                                    gap: "20px",
                                    flexDirection: { xs: "column" },
                                    margin: '0 auto',
                                }}
                            >
                                <Typography
                                    sx={{
                                        color: "black",
                                        fontWeight: "400",
                                        textAlign: "left",
                                        fontSize: { xs: "14px", sm: "16px" },
                                    }}
                                >
                                    Deposit Receipt
                                </Typography>
                                {isLoading ? (
                                    <Skeleton height={200} />
                                ) : (
                                    <>
                                        <Box
                                            sx={{
                                                width: "100%",
                                                margin: '0 auto',
                                                height: "200px",
                                                overflow: "hidden",
                                                background: "#fff",
                                                position: "relative",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                borderRadius: "10px",
                                                border: "1px solid #CACACA",
                                            }}
                                        >
                                            {!mediaTypes ? (
                                                <label htmlFor="document">
                                                    <Button
                                                        component="span"
                                                        sx={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "center",
                                                            flexDirection: "column",
                                                            outline: "none",
                                                            border: "none",
                                                            backgroundColor: "transparent",
                                                            textTransform: "capitalize",
                                                        }}
                                                        type="button"
                                                    >
                                                        <img src={UPLOAD_GALLERY_ICON} alt="Upload Icon" width={"42px"} />
                                                        <Typography
                                                            variant="body2"
                                                            sx={{
                                                                fontSize: "14px",
                                                                fontWeight: "600",
                                                                color: "#000000",
                                                                margin: "8px 0px !important",
                                                            }}
                                                        >
                                                            Deposit Receipt
                                                        </Typography>
                                                        <Typography
                                                            variant="body2"
                                                            sx={{
                                                                fontSize: "14px",
                                                                fontWeight: "600",
                                                                color: "#9C9C9C",
                                                            }}
                                                        >
                                                            {`(PSD, DOC, PDF, JPG, PNG)`}
                                                        </Typography>
                                                        <input
                                                            accept="image/*,.pdf,.doc,.docx,.psd"
                                                            style={{ display: "none" }}
                                                            id="document"
                                                            type="file"
                                                            onChange={handleUploadMedia}
                                                        />
                                                    </Button>
                                                </label>
                                            ) : (
                                                <Box sx={{ textAlign: "center" }}>
                                                    {mediaTypes === 'image' ? (
                                                        <img src={mediaPreview} alt="Uploaded" style={{ width: "100%", height: "100%", objectFit: "cover" }} />
                                                    ) : (
                                                        <>
                                                            <img src={DOCUMENT_ICON} alt="document" style={{ width: "100px" }} />
                                                            <Typography
                                                                variant="body2"
                                                                sx={{
                                                                    fontSize: "14px",
                                                                    fontWeight: "600",
                                                                    color: "#9C9C9C",
                                                                    padding: "5px 0px",
                                                                }}
                                                            >
                                                                Document Uploaded
                                                            </Typography>
                                                        </>
                                                    )}
                                                    <label htmlFor="document">
                                                        <IconButton
                                                            component="span"
                                                            sx={{
                                                                width: "40px",
                                                                height: "40px",
                                                                borderRadius: "16px",
                                                                backgroundColor: "#ffffff",
                                                                boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                                                                transition: "0.5s",
                                                                position: "absolute",
                                                                right: "5px",
                                                                top: "5px",
                                                                "&:hover": {
                                                                    backgroundColor: "#ffffff",
                                                                }
                                                            }}
                                                        >
                                                            <EditIcon sx={{ color: "#000", fontSize: "26px" }} />
                                                        </IconButton>
                                                        <input
                                                            accept="image/*,.pdf,.doc,.docx,.psd"
                                                            style={{ display: "none" }}
                                                            id="document"
                                                            type="file"
                                                            onChange={handleUploadMedia}
                                                        />
                                                        <IconButton
                                                            component="span"
                                                            sx={{
                                                                width: "40px",
                                                                height: "40px",
                                                                borderRadius: "16px",
                                                                backgroundColor: "#ffffff",
                                                                boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                                                                transition: "0.5s",
                                                                position: "absolute",
                                                                right: "50px",
                                                                top: "5px",
                                                                "&:hover": {
                                                                    backgroundColor: "#ffffff",
                                                                }
                                                            }}
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                setMediaUploads(null);
                                                                setMediaPreview(null);
                                                                setMediaTypes(null);
                                                            }}
                                                        >
                                                            <CloseIcon sx={{ color: "#000", fontSize: "26px" }} />
                                                        </IconButton>
                                                    </label>
                                                </Box>
                                            )}
                                        </Box>
                                        {formik.touched.receipt && formik.errors.receipt ? (
                                            <Typography
                                                sx={{
                                                    color: "red",
                                                    fontWeight: "400",
                                                    fontSize: { xs: "14px", sm: "14px" },
                                                }}
                                            >
                                                {formik.errors.receipt}
                                            </Typography>
                                        ) : null}
                                    </>
                                )}
                            </Box>

                        </Grid>
                        <Grid item xs={12}>
                            <CustomSubmitButton
                                btnTitle={typeCheck ? "Update Proof" : "Send Proof"}
                                loading={isLoading}
                                type="submit"
                            />
                        </Grid>
                    </Grid>
                </Box>
            </form>
        </Dialog>
    );
};

export default NewDepositModel;