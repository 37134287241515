import React, { Fragment, useEffect, useState } from "react";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import { Box, CircularProgress, IconButton, Typography } from "@mui/material";
import DayWisePerformance from "./DayWisePerformance";
import TopHeader from "../../../../Components/TopHeader/TopHeader";
import { useNavigate } from "react-router-dom";
import LinearProgress from "@mui/material/LinearProgress";
import { useFormik } from "formik";
import dayjs from "dayjs";
import * as Yup from "yup";
import PerformanceDailyGraph from "./PerformanceMonthlyGraph";
import CustomerSelection from "./CustomerSelection";
import {
  collection,
  doc,
  getDoc,
  onSnapshot,
  query,
  setDoc,
  Timestamp,
  updateDoc,
} from "firebase/firestore";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { DATABASE } from "../../../../Config/firebase";
import { toast } from "react-toastify";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
dayjs.extend(isSameOrBefore);
const AddMonthlyGraph = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [total_Monthly_percantage, setTotal_monthly_percantage] = useState(0);
  const [activeStep, setActiveStep] = useState(
    parseInt(sessionStorage.getItem("activeStep")) || 0
  );
  const [ledgers, setLedgers] = useState([]);
  const steps = ["Days Performance", "Month Graph", "Users Selection"];
  const handleNext = () => {
    const nextStep = Math.min(activeStep + 1, steps.length - 1);
    setActiveStep(nextStep);
    sessionStorage.setItem("activeStep", nextStep);
  };
  const handleBack = () => {
    const prevStep = Math.max(activeStep - 1, 0);
    setActiveStep(prevStep);
    sessionStorage.setItem("activeStep", prevStep);
  };
  const isFormValid = () => {
    const hasUsers = formik.values.select_user.length > 0;
    return hasUsers;
  };
  const isStepValid = (step) => {
    switch (step) {
      case 0:
        return formik.values.dayWisePerformance.select_days.some(
          (day) => day.percentage > 0
        );
      case 1:
        return true;
      case 2:
        return formik.values.select_user.length > 0;
      default:
        return false;
    }
  };
  const getLedgersList = () => {
    setIsLoading(true);
    const q = query(collection(DATABASE, "ledgers"));
    return onSnapshot(
      q,
      (querySnapshot) => {
        const allData = querySnapshot.docs
          .filter((doc) => doc.data())
          .map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
        setLedgers(allData);
        setIsLoading(false);
      },
      (error) => {
        console.error("Error fetching ledgers:", error);
        setIsLoading(false);
      }
    );
  };
  useEffect(() => {
    if (activeStep == 2) {
      getLedgersList();
    }
  }, [activeStep]);
  const formik = useFormik({
    initialValues: {
      select_user: [],
      dayWisePerformance: {
        select_Month: dayjs().startOf("month"),
        select_days: Array.from({ length: dayjs().daysInMonth() }, (_, i) => ({
          day: i + 1,
          percentage: 0,
        })),
      },
    },
    validationSchema: Yup.object({
      select_user: Yup.array().min(1, "At least one user is required."),
      dayWisePerformance: Yup.object({
        select_days: Yup.array().of(
          Yup.object({
            percentage: Yup.number()
              .required("Percentage is required.")
              .typeError("Must be a number")
              .min(-100, "Percentage cannot be less than -100.")
              .max(100, "Percentage cannot be more than 100."),
          })
        ),
      }),
    }),
    onSubmit: async (values, { resetForm }) => {
      setIsLoading(true);
      setLoadingProgress(10);
      try {
        const existingLedgers = ledgers.filter((ledger) =>
          values.select_user.includes(ledger.user_id)
        );
        const multipleUsersEntry = [];
        const yearlyPercentagesMap = {};

        for (const userID of values.select_user) {
          const userLedgers = existingLedgers.filter(
            (entry) => entry.user_id === userID
          );

          if (userLedgers.length === 0) {
            console.warn(`User ${userID} has no ledgers. Skipping.`);
            continue;
          }

          let totalMonthlyProfitLoss = 0;
          let closingPercentageAccumulated = 0;
          let previousBalance = null;
          const graphPoints = [];

          // Calculate totalMonthlyPercentage and day_avg_percentage first
          const totalMonthlyPercentage =
            values.dayWisePerformance.select_days.reduce(
              (acc, dayData) => acc + (parseFloat(dayData.percentage) || 0),
              0
            );
          const day_avg_percentage =
            totalMonthlyPercentage /
            values.dayWisePerformance.select_days.length;

          values.dayWisePerformance.select_days.forEach((dayData, index) => {
            const day = index + 1;
            const currentDate = dayjs(
              values.dayWisePerformance.select_Month
            ).date(day);

            const currentPercentage = parseFloat(dayData.percentage) || 0;

            const ledgerEntriesBeforeDate = userLedgers
              .filter((entry) =>
                dayjs(entry.date.toDate()).isSameOrBefore(currentDate)
              )
              .sort((a, b) =>
                dayjs(b.date.toDate()).diff(dayjs(a.date.toDate()))
              );

            if (ledgerEntriesBeforeDate.length > 0) {
              const ledgerEntry = ledgerEntriesBeforeDate[0];
              previousBalance = ledgerEntry.current_balance || 0;
            } else {
              previousBalance = previousBalance || 0;
            }

            // Now use day_avg_percentage in dailyProfitLoss calculation
            const dailyProfitLoss =
              (previousBalance * day_avg_percentage) / 100;
            console.log(totalMonthlyPercentage, "monthly");
            console.log(day_avg_percentage, "daily");
            const closingBalance = previousBalance + dailyProfitLoss;
            totalMonthlyProfitLoss += dailyProfitLoss;

            const graphPoint = {
              closing_balance: closingBalance,
              opening_balance: previousBalance,
              percentage: currentPercentage,
              opening_percentage:
                index === 0 ? 0 : closingPercentageAccumulated,
              closing_percentage: currentPercentage,
              date: Timestamp.fromDate(currentDate.toDate()),
            };
            graphPoints.push(graphPoint);

            previousBalance = closingBalance;
            closingPercentageAccumulated = currentPercentage;
          });

          multipleUsersEntry.push({
            doc_id: `${userID}_${dayjs().format("YYYYMMDDHHmmss")}`,
            start_date: Timestamp.fromDate(
              values.dayWisePerformance.select_Month.startOf("month").toDate()
            ),
            end_date: Timestamp.fromDate(
              values.dayWisePerformance.select_Month.endOf("month").toDate()
            ),
            graph_month: values.dayWisePerformance.select_Month.month() + 1,
            graph_month_date: Timestamp.fromDate(
              values.dayWisePerformance.select_Month.startOf("month").toDate()
            ),
            graph_year: values.dayWisePerformance.select_Month.year(),
            graph_points: graphPoints,
            total_Monthly_Profit_Loss: parseFloat(
              totalMonthlyProfitLoss.toFixed(2)
            ),
            user_id: userID,
            total_Monthly_percantage: totalMonthlyPercentage,
            day_avg_percentage,
          });
          // Update yearlyPercentagesMap with totalMonthlyPercentage
          if (!yearlyPercentagesMap[userID]) {
            yearlyPercentagesMap[userID] = Array(12).fill(0);
          }
          const currentMonth = values.dayWisePerformance.select_Month.month();
          yearlyPercentagesMap[userID][currentMonth] += totalMonthlyPercentage;
        }

        // Continue with the rest of your processing steps...
        setLoadingProgress(40);
        await Promise.all(
          multipleUsersEntry.map((entry) =>
            saveMonthlyGraph(entry.doc_id, entry)
          )
        );
        setLoadingProgress(70);

        if (multipleUsersEntry.length > 0) {
          const multipleUsersLedgers = [];

          for (const entry of multipleUsersEntry) {
            const {
              user_id,
              total_Monthly_Profit_Loss,
              total_Monthly_percantage,
            } = entry;
            const userDoc = doc(DATABASE, "users", user_id);
            const userData = await getDoc(userDoc);
            const currentNetBalance = userData.exists()
              ? userData.data()?.net_balance || 0
              : 0;
            const updatedNetBalance =
              currentNetBalance + parseFloat(total_Monthly_Profit_Loss);

            multipleUsersLedgers.push({
              profit_loss_balance: parseFloat(total_Monthly_Profit_Loss),
              user_id,
              profit_loss_percent: total_Monthly_percantage,
              date: Timestamp.now(),
              doc_id: `${user_id}_${dayjs().format("YYYYMMDDHHmmss")}`,
              new_txn_amount: parseFloat(total_Monthly_Profit_Loss),
              previous_balance: currentNetBalance,
              current_balance: updatedNetBalance,
            });
          }

          await Promise.all(
            multipleUsersLedgers.map((ledger) =>
              saveMonthlyLedgers(ledger.doc_id, ledger)
            )
          );
          const transectionUpdatePromises = multipleUsersLedgers.map(
            async ({ user_id, new_txn_amount, profit_loss_percent }) => {
              const doc_id = `${user_id}_${dayjs().format("YYYYMMDDHHmmss")}`;
              const transectionParams = {
                admin_commission: 0.0,
                date: Timestamp.now(),
                request_status: 5,
                request_type: 2,
                notes: "",
                payment_receipt: "",
                user_id,
                remaining_balance: 0,
                profit_loss: new_txn_amount,
                total_amount: 0,
                profit_loss_percent: profit_loss_percent,
                request_approval_time: Timestamp.now(),
                transection_fee: 0,
                txn_id: doc_id,
                withdraw_amount: 0,
                otp: "0",
                otp_verify: true,
                doc_id,
              };
              await setDoc(
                doc(DATABASE, "transection_requests", doc_id),
                transectionParams
              );
            }
          );
          await Promise.all(transectionUpdatePromises);

          const netBalanceUpdatePromises = multipleUsersLedgers.map(
            async ({ user_id, current_balance }) => {
              if (current_balance !== undefined) {
                const updateNetBalance = { net_balance: current_balance };
                await updateDoc(
                  doc(DATABASE, "users", user_id),
                  updateNetBalance
                );
              }
            }
          );
          await Promise.all(netBalanceUpdatePromises);
        }

        const yearlyPromises = Object.keys(yearlyPercentagesMap).map(
          async (userID) => {
            const existingYearlyDoc = doc(DATABASE, "yearly_graph", userID);
            const existingYearlyData = await getDoc(existingYearlyDoc);
            let yearlyEntries = existingYearlyData.exists()
              ? existingYearlyData.data().graph_points
              : [];

            const currentMonth =
              values.dayWisePerformance.select_Month.month() + 1;
            const currentYear = values.dayWisePerformance.select_Month.year();
            const totalMonthlyPercentage =
              yearlyPercentagesMap[userID][currentMonth - 1];

            // Remove the oldest entry if there are already 12 months
            if (yearlyEntries.length >= 12) {
              yearlyEntries.pop();
            }

            // Add the new month at the beginning of the array
            yearlyEntries.unshift({
              month: currentMonth,
              year: currentYear,
              percentage: totalMonthlyPercentage,
              created_at: Timestamp.now(),
            });

            const yearlyData = {
              user_id: userID,
              doc_id: `${userID}_${dayjs().format("YYYYMMDDHHmmss")}`,
              graph_points: yearlyEntries,
              total_yearly_percentage: yearlyEntries.reduce(
                (acc, entry) => acc + entry.percentage,
                0
              ),
            };

            await setDoc(existingYearlyDoc, yearlyData);
            console.log("Successfully updated yearly data for user:", userID);
          }
        );
     

        await Promise.all(yearlyPromises);
        setLoadingProgress(100);
        toast.success("Monthly Graph Added Successfully!");
      } catch (error) {
        console.error("Error submitting form:", error);
        toast.error("Failed to add Monthly Graph.");
      } finally {
        setIsLoading(false);
        resetForm();
        setActiveStep(0);
        sessionStorage.removeItem("activeStep");
        navigate(-1);
      }
    },
  });
  console.log(
    "formik",
    formik?.values?.dayWisePerformance?.select_days?.length
  );

  const saveMonthlyGraph = async (docId, params) => {
    try {
      await setDoc(doc(DATABASE, "monthly_grpahs", docId), params);
      return true;
    } catch (error) {
      console.error("Error saving monthly graph:", error);
      toast.error("An error occurred while saving. Please try again.");
      return false;
    }
  };
  const saveMonthlyLedgers = async (docId, params) => {
    try {
      await setDoc(doc(DATABASE, "ledgers", docId), params);
      return true;
    } catch (error) {
      console.error("Error saving monthly graph:", error);
      toast.error("An error occurred while saving. Please try again.");
      return false;
    }
  };
  useEffect(() => {
    if (activeStep === 0) {
      sessionStorage.setItem(
        "select_Month",
        formik.values.dayWisePerformance.select_Month.toISOString()
      );
      sessionStorage.setItem(
        "select_days",
        JSON.stringify(formik.values.dayWisePerformance.select_days)
      );
    } else if (activeStep === 2) {
      sessionStorage.setItem(
        "select_user",
        JSON.stringify(formik.values.select_user)
      );
    }
  }, [formik.values, activeStep]);
  return (
    <Fragment>
      <TopHeader
        title={"Add Monthly"}
        onClick={() => {
          navigate(-1);
          sessionStorage.clear();
        }}
      />
      <form
        onSubmit={(e) => {
          e.preventDefault();
          formik.handleSubmit();
          return false;
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
          }}
        >
          {loadingProgress ? (
            <Box
              sx={{
                position: "fixed",
                inset: 0,
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "rgba(0, 0, 0, 0.8)",
                zIndex: 1000,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                overflow: "hidden",
                height: "100vh",
              }}
            >
              <LinearProgress
                variant="determinate"
                value={loadingProgress}
                sx={{ width: "80%", borderRadius: "5px", marginBottom: 2 }}
              />
              <Typography
                variant="body1"
                sx={{
                  color: "#fff",
                  fontWeight: 600,
                  textAlign: "center",
                  fontSize: 20,
                }}
              >
                Please wait while we process your request. You can check the
                progress above.
              </Typography>
            </Box>
          ) : (
            ""
          )}
          <Box
            sx={{
              maxWidth: { xs: "95%", lg: "95%", xl: "95%" },
              width: "100%",
              p: 3,
              margin: "50px auto 20px auto",
            }}
          >
            <Stepper
              activeStep={activeStep}
              alternativeLabel
              sx={{ padding: "12px", width: "500px", margin: "0 auto" }}
            >
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <Box sx={{ paddingTop: "32px" }}>
              {activeStep === 0 && (
                <DayWisePerformance
                  dayWisePerformance={formik?.values?.dayWisePerformance}
                  setFieldValue={formik?.setFieldValue}
                  errors={formik.errors.dayWisePerformance?.select_days}
                  touched={formik.touched.dayWisePerformance?.select_days}
                  total_Monthly_percantage={total_Monthly_percantage}
                  setTotal_monthly_percantage={setTotal_monthly_percantage}
                />
              )}
              {activeStep === 1 && (
                <PerformanceDailyGraph
                  dayWisePerformance={formik?.values?.dayWisePerformance}
                />
              )}
              {activeStep === 2 && (
                <CustomerSelection
                  select_user={formik?.values?.select_user}
                  setFieldValue={formik?.setFieldValue}
                  total_Monthly_percantage={total_Monthly_percantage}
                />
              )}
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                gridGap: "10px",
              }}
            >
              <IconButton
                onClick={handleBack}
                disabled={activeStep === 0}
                sx={{
                  background:
                    "linear-gradient(148.97deg, #0C2C50 0%, #450F5B 100%)",
                  color: "#FFFFFF",
                  "&:hover": {
                    background:
                      "linear-gradient(148.97deg, #0C2C50 0%, #450F5B 100%)",
                    color: "#FFFFFF",
                  },
                  "&.Mui-disabled": {
                    background: "#B0BEC5",
                    color: "#FFFFFF",
                  },
                }}
              >
                <KeyboardArrowLeftIcon />
              </IconButton>
              {activeStep !== 2 && (
                <IconButton
                  onClick={handleNext}
                  disabled={
                    !isStepValid(activeStep) || activeStep === steps.length - 1
                  }
                  sx={{
                    background:
                      "linear-gradient(148.97deg, #0C2C50 0%, #450F5B 100%)",
                    color: "#FFFFFF",
                    "&:hover": {
                      background:
                        "linear-gradient(148.97deg, #0C2C50 0%, #450F5B 100%)",
                      color: "#FFFFFF",
                    },
                    "&.Mui-disabled": {
                      background: "#B0BEC5",
                      color: "#FFFFFF",
                    },
                  }}
                >
                  <KeyboardArrowRightIcon />
                </IconButton>
              )}
              {activeStep === 2 && (
                <Button
                  type={"Submit"}
                  disabled={!isFormValid()}
                  sx={{
                    fontWeight: "700",
                    fontSize: "18px",
                    height: "48px",
                    maxWidth: "150px",
                    width: "100%",
                    textTransform: "unset",
                    background:
                      "linear-gradient(148.97deg, #0C2C50 0%, #450F5B 100%)",
                    color: "#ffffff !important",
                    border: "1.5px solid #0C2C50",
                    "&:hover": {
                      border: "1.5px solid #0C2C50",
                      backgroundColor: "transparent !important",
                      color: "#ffffff !important",
                    },
                    "&.Mui-disabled": {
                      background: "#B0BEC5",
                      color: "#FFFFFF",
                      border: "1.5px solid #B0BEC5",
                    },
                  }}
                  className={"customBtn"}
                >
                  {isLoading ? (
                    <>
                      <CircularProgress />
                    </>
                  ) : (
                    "Submit"
                  )}
                </Button>
              )}
            </Box>
          </Box>
        </Box>
      </form>
    </Fragment>
  );
};

export default AddMonthlyGraph;
