import { Box, Checkbox, Grid, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomSearch from "../../../../Components/CustomSearch/CustomSearch";
import TableContainer from "../../../../Components/TableContainer/TableContainer";
import { collection, orderBy, query, onSnapshot } from "firebase/firestore";
import { DATABASE } from "../../../../Config/firebase";
import useDebounce from '../../../../Components/useDebounce/useDebounce';
import moment from "moment";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const CustomerSelection = ({ select_user, setFieldValue, total_Monthly_percantage }) => {
    const [pageSize, setPageSize] = useState(50);
    const [customerList, setCustomerList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [searchCustomer, setSearchCustomer] = useState("");
    const debouncedSearch = useDebounce(searchCustomer, 500);
    const [page, setPage] = useState(0);
    const [totalCount, setTotalCount] = useState(0);

    // Automatically select all customers on data fetch
    useEffect(() => {
        if (customerList.length > 0) {
            const allCustomerIds = customerList.map(customer => customer.id);
            setFieldValue('select_user', allCustomerIds);
        }
    }, [customerList, setFieldValue]);

    const handleSelectUser = (userId) => {
        let updatedSelection;
        if (select_user.includes(userId)) {
            updatedSelection = select_user.filter(id => id !== userId);
        } else {
            updatedSelection = [...select_user, userId];
        }
        setFieldValue('select_user', updatedSelection);
    };

    const handleSelectAllToggle = () => {
        if (select_user.length === customerList.length) {
            setFieldValue('select_user', []); // Deselect all
        } else {
            const allCustomerIds = customerList.map(customer => customer.id);
            setFieldValue('select_user', allCustomerIds); // Select all
        }
    };

    const columns = [
        {
            field: "rowid",
            headerName: (
                <Checkbox
                    checked={select_user.length === customerList.length}
                    indeterminate={select_user.length > 0 && select_user.length < customerList.length}
                    onChange={handleSelectAllToggle}
                />
            ),
            minWidth: 110,
            renderCell: (params) => (
                <Checkbox
                    checked={select_user.includes(params.row.id)}
                    onChange={() => handleSelectUser(params.row.id)}
                />
            ),
        },
        {
            field: "profile_image",
            headerName: "Profile",
            minWidth: 50,
            renderCell: (params) => (
                params?.row?.profile_image ? (
                    <img
                        src={params.row.profile_image}
                        alt="profile"
                        style={{ width: '40px', height: '40px', borderRadius: '10px' }}
                    />
                ) : (
                    <AccountCircleIcon sx={{ fontSize: '40px' }} />
                )
            ),
        },
        {
            field: "full_name",
            headerName: "Full Name",
            minWidth: 250,
            renderCell: (params) => {
                const fullName = params?.row?.full_name || "--";
                return (
                    <Tooltip title={fullName.length > 25 ? fullName : ""}>
                        <span>{fullName.length > 25 ? `${fullName.substring(0, 25)}...` : fullName}</span>
                    </Tooltip>
                );
            },
        },
        {
            field: "email",
            headerName: "Email",
            minWidth: 200,
            renderCell: (params) => (
                <Tooltip title={params?.row?.email || "--"}>
                    <span>{params?.row?.email || "--"}</span>
                </Tooltip>
            ),
        },
        {
            field: "phone",
            headerName: "Phone Number",
            minWidth: 200,
            renderCell: (params) => {
                const phone = params?.row?.phone?.split(',') || [];
                return (
                    <Tooltip title={phone[1] ? `${phone[1]} - ${phone[2]}` : "--"}>
                        <span>{phone[1] ? `${phone[1]} - ${phone[2]}` : "--"}</span>
                    </Tooltip>
                );
            },
        },
        {
            field: "registered_date",
            headerName: "Registered Date",
            minWidth: 250,
            renderCell: (params) => {
                const date = params?.row?.registered_date;
                return date ? (
                    <div>{moment(date.seconds * 1000).format('MMMM Do YYYY, h:mm:ss A')}</div>
                ) : "--";
            },
        },
        {
            field: "net_balance",
            headerName: "Net Balance",
            minWidth: 200,
            renderCell: (params) => (
                <Tooltip title={`$${params.row.net_balance?.toFixed(2) || '0.00'}`}>
                    <span>{`$${params.row.net_balance?.toFixed(2) || '0.00'}`}</span>
                </Tooltip>
            ),
        },
        {
            field: "current_balance",
            headerName: "Updated Balance",
            minWidth: 200,
            renderCell: (params) => {
                const netBalance = params?.row?.net_balance || 0;
                const updatedBalance = netBalance * (1 + total_Monthly_percantage / 100);
                return (
                    <Tooltip title={`$${updatedBalance.toFixed(2)}`}>
                        <span>{`$${updatedBalance.toFixed(2)}`}</span>
                    </Tooltip>
                );
            },
        },
    ];

    const fetchCustomerList = async (currentPage, pageSize, debouncedSearch) => {
        setIsLoading(true);
        const customerQuery = query(
            collection(DATABASE, "users"),
            orderBy("registered_date", "desc")
        );
        onSnapshot(customerQuery, (snapshot) => {
            const data = snapshot.docs
                .map((doc, index) => ({
                    rowid: index + 1,
                    id: doc.id,
                    ...doc.data(),
                }))
                .filter(doc => doc.role === 1);

            const searchFilter = debouncedSearch.toLowerCase();
            const filteredData = data.filter(
                ({ email = '', full_name = '', net_balance = '' }) =>
                    email.toLowerCase().includes(searchFilter) ||
                    full_name.toLowerCase().includes(searchFilter) ||
                    net_balance.toString().includes(searchFilter)
            );

            setCustomerList(filteredData.slice(currentPage * pageSize, (currentPage + 1) * pageSize));
            setTotalCount(filteredData.length);
            setIsLoading(false);
        }, (error) => {
            console.error("Error fetching customers:", error);
            setIsLoading(false);
        });
    };

    useEffect(() => {
        fetchCustomerList(page, pageSize, debouncedSearch);
    }, [debouncedSearch, page, pageSize]);

    return (
        <Box sx={{ width: "100%" }}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                    <CustomSearch
                        placeholder="Search Customer (Email, Full Name, Ledger)"
                        value={searchCustomer}
                        onChange={(event) => setSearchCustomer(event.target.value)}
                        onClear={() => setSearchCustomer("")}
                        style={{ border: '1px solid #000' }}
                    />
                </Grid>
            </Grid>
            <Box sx={{ width: "100%", paddingTop: '24px' }}>
                <TableContainer
                    rows={customerList}
                    columns={columns}
                    loading={isLoading}
                    page={page}
                    pageSize={pageSize}
                    totalCount={totalCount}
                    handlePageChange={(newPage) => setPage(newPage)}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    paginationShow={true}
                    SXHEAD={{
                        background: "linear-gradient(148.97deg, #0C2C50 0%, #450F5B 100%)",
                        color: '#fff'
                    }}
                />
            </Box>
        </Box>
    );
};

export default CustomerSelection;
