import * as React from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";

const CustomMonthYearPicker = ({
  value,
  onChange,
  sx = {},
  name,
  InputProps,
}) => {
  // Calculate the previous month and max date as the end of that month
  const previousMonth = dayjs().subtract(1, "month");
  const maxDate = previousMonth.endOf("month");

  // Ensure value defaults to previous month if it's undefined or in the current/future month
  const validValue = 
    !value || dayjs(value).isAfter(maxDate) ? previousMonth : dayjs(value);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        views={["year", "month"]}
        value={validValue}
        onChange={onChange}
        sx={{
          width: "100%",
          backgroundColor: "#fff",
          ...sx,
        }}
        InputProps={InputProps}
        name={name}
        maxDate={maxDate} 
      />
    </LocalizationProvider>
  );
};

export default CustomMonthYearPicker;
