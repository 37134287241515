import { Box, Grid, Typography } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import CustomDateTimePicker from "../../../../Components/CustomDatePicker/CustomDatePicker";
import CustomTextField from "../../../../Components/CustomTextField/CustomTextField";
import dayjs from "dayjs";
import Skeleton from "react-loading-skeleton";
import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import { DATABASE } from "../../../../Config/firebase";

const DayWisePerformance = ({
  dayWisePerformance,
  setFieldValue,
  total_Monthly_percantage,
  setTotal_monthly_percantage,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [netBalance, setNetBalance] = useState(0);
  const [profitLoss, setProfitLoss] = useState(0);

  useEffect(() => {
    const unsubscribe = getCustomerList();
    return () => unsubscribe();
  }, []);

  const getCustomerList = () => {
    setIsLoading(true);
    const q = query(
      collection(DATABASE, "users"),
      orderBy("registered_date", "desc")
    );
    return onSnapshot(
      q,
      (querySnapshot) => {
        const allData = querySnapshot.docs
          .filter((doc) => doc.data().role === 1)
          .map((doc, index) => ({
            rowid: index + 1,
            id: doc.id,
            ...doc.data(),
          }));
        const totalBalance = allData.reduce(
          (acc, data) => acc + (data?.net_balance || 0),
          0
        );
        setNetBalance(totalBalance);
        setIsLoading(false);
      },
      (error) => {
        console.error("Error fetching customers:", error);
        setIsLoading(false);
      }
    );
  };

  const getDaysInMonth = (month) => dayjs(month).daysInMonth();

  // Set initial month to previous month (e.g., October if current month is November)
  useEffect(() => {
    const lastMonth = dayjs().subtract(1, "month");
    const daysInLastMonth = getDaysInMonth(lastMonth);

    setFieldValue("dayWisePerformance.select_Month", lastMonth);
    setFieldValue(
      "dayWisePerformance.select_days",
      Array.from({ length: daysInLastMonth }, (_, i) => ({
        day: i + 1,
        percentage: 0,
      }))
    );
  }, [setFieldValue]);

  useEffect(() => {
    if (dayWisePerformance?.select_days) {
      const total = dayWisePerformance.select_days.reduce((acc, day) => {
        const percentage = Number(day.percentage);
        return acc + (isNaN(percentage) ? 0 : percentage);
      }, 0);
      setTotal_monthly_percantage(total);
    }
  }, [dayWisePerformance?.select_days, setTotal_monthly_percantage]);

  useEffect(() => {
    const calculatedProfitLoss = (netBalance * total_Monthly_percantage) / 100;
    setProfitLoss(calculatedProfitLoss);
  }, [netBalance, total_Monthly_percantage]);

  return (
    <Fragment>
      <Grid container spacing={3}>
        <Grid item xs={3}>
          <Typography sx={{ color: "#000", fontWeight: "400", textAlign: "left", fontSize: { xs: "16px", sm: "18px" }, fontFamily: "Outfit", mb: "8px" }}>
            Select Month
          </Typography>
          {isLoading ? (
            <Skeleton height={54} />
          ) : (
            <CustomDateTimePicker
              InputProps={{
                sx: {
                  border: "none",
                  "& .MuiFormControl-root": {
                    border: "none",
                  },
                },
              }}
              value={dayWisePerformance.select_Month}
              maxDate={dayjs().subtract(1, "month").endOf("month")}
              inputFormat={"MM/YYYY"}
              name="select_Month"
              onChange={(newValue) => {
                setFieldValue("dayWisePerformance.select_Month", newValue);
                setFieldValue(
                  "dayWisePerformance.select_days",
                  Array.from({ length: getDaysInMonth(newValue) }, (_, i) => ({
                    day: i + 1,
                    percentage: 0,
                  }))
                );
              }}
            />
          )}
        </Grid>
        <Grid item xs={2}>
          <Typography sx={{ color: "#000", fontWeight: "400", textAlign: "left", fontSize: { xs: "16px", sm: "18px" }, fontFamily: "Outfit", mb: "4px" }}>
            Monthly (%)
          </Typography>
          <Typography sx={{ color: total_Monthly_percantage < 0 ? "red" : total_Monthly_percantage > 0 ? "#000" : "#000", fontWeight: "400", textAlign: "left", fontSize: { xs: "16px", sm: "18px" }, fontFamily: "Outfit", mb: "0px" }}>
            {isLoading ? <Skeleton height={54} /> : `${total_Monthly_percantage.toFixed(2)} %`}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography sx={{ color: "#000", fontWeight: "400", textAlign: "left", fontSize: { xs: "16px", sm: "18px" }, fontFamily: "Outfit", mb: "4px" }}>
           Day Average(%)
          </Typography>
          <Typography sx={{ color: total_Monthly_percantage < 0 ? "red" : total_Monthly_percantage > 0 ? "#000" : "#000", fontWeight: "400", textAlign: "left", fontSize: { xs: "16px", sm: "18px" }, fontFamily: "Outfit", mb: "0px" }}>
            {isLoading ? <Skeleton height={54} /> : `${(+total_Monthly_percantage / +dayWisePerformance?.select_days?.length).toFixed(6)} %`}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography sx={{ color: "#000", fontWeight: "400", textAlign: "left", fontSize: { xs: "16px", sm: "18px" }, fontFamily: "Outfit", mb: "4px" }}>
            Net Balance
          </Typography>
          <Typography sx={{ color: "#000", fontWeight: "400", textAlign: "left", fontSize: { xs: "16px", sm: "18px" }, fontFamily: "Outfit" }}>
            {isLoading ? <Skeleton height={54} /> : new Intl.NumberFormat("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(netBalance)}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography sx={{ color: "#000", fontWeight: "400", textAlign: "left", fontSize: { xs: "16px", sm: "18px" }, fontFamily: "Outfit", mb: "4px" }}>
            Profit Loss Month
          </Typography>
          <Typography sx={{ color: profitLoss < 0 ? "red" : profitLoss > 0 ? "green" : "inherit", fontWeight: "400", textAlign: "left", fontSize: { xs: "16px", sm: "18px" }, fontFamily: "Outfit", mb: "0px" }}>
            {isLoading ? <Skeleton height={54} /> : new Intl.NumberFormat("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(profitLoss)}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Box sx={{ width: "100%", display: "flex", flexWrap: "wrap", gridGap: "10px", justifyContent: "center", alignItems: "center", paddingTop: "10px" }}>
              {Array.from({ length: getDaysInMonth(dayWisePerformance.select_Month) }, (_, i) => (
                <Box key={i} sx={{ width: "100px" }}>
                  <CustomTextField
                    label={`Day ${i + 1} (%)`}
                    type="number"
                    name={`dayWisePerformance.select_days[${i}].percentage`}
                    value={dayWisePerformance?.select_days[i]?.percentage || ""}
                    onChange={(e) => {
                      const value = e.target.value;
                      const updatedPercentage = value === "" ? 0 : parseFloat(value);
                      const updatedDays = [...dayWisePerformance.select_days];
                      updatedDays[i] = { day: i + 1, percentage: updatedPercentage };
                      setFieldValue("dayWisePerformance.select_days", updatedDays);
                    }}
                    InputProps={{ inputProps: { min: 0 } }}
                    placeholder={"Percent"}
                    sx={{
                      mt: 0,
                      "& .MuiOutlinedInput-notchedOutline": { outline: "none", border: "1px solid #000" },
                      "& .MuiInputBase-root": { borderRadius: "12px", background: "white", fontFamily: "Outfit" },
                    }}
                    style={{ color: "#000", fontSize: "16px" }}
                  />
                </Box>
              ))}
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default DayWisePerformance;
