import { Box, Typography } from "@mui/material";
import * as React from "react";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import GroupIcon from "@mui/icons-material/Group";
import PersonIcon from "@mui/icons-material/Person";
import GroupsIcon from "@mui/icons-material/Groups";
import WalletIcon from "@mui/icons-material/Wallet";
import AddCardIcon from "@mui/icons-material/AddCard";
import LineChart from "../../../Components/DashboardChart/LineChart";
import { collection, getDocs } from "firebase/firestore";
import { DATABASE } from "../../../Config/firebase";
import Skeleton from "react-loading-skeleton";
import { formatNumber } from "../../../Utils/Services";
const Dashboard = () => {
  const [totalCustomer, setTotalCustomer] = React.useState(0);
  const [corporateCustomer, setCorporateCustomer] = React.useState(0);
  const [individualCustomer, setIndividualCustomer] = React.useState(0);
  const [totalDepositAmount, setTotalDepositAmount] = React.useState(0);
  const [totalWithdrawAmount, setTotalWithdrawAmount] = React.useState(0)
  const [isLoading, setIsLoading] = React.useState(false);
  const [allTransactionsList, setAllTransactionsList] = React.useState([]);
  React.useEffect(() => {
    const getTotalCustomer = async () => {
      setIsLoading(true);
      try {
        const querySnapshot = await getDocs(collection(DATABASE, "users"));
        const allData = querySnapshot.docs
          .filter(doc => doc?.data()?.role === 1 && doc?.data()?.status === 1)
          .map((doc, index) => ({
            id: doc.id,
            ...doc.data(),
          }));
        setTotalCustomer(allData?.length || 0);
        setCorporateCustomer(allData?.filter((customer) => customer?.user_type === 1)?.length || 0);
        setIndividualCustomer(allData?.filter(customer => customer?.user_type === 0)?.length || 0);
      } catch (error) {
        console.error("Error fetching customers:", error);
      } finally {
        setIsLoading(false);
      }
    };
    const getTransactionHistory = async () => {
      try {
        const querySnapshot = await getDocs(collection(DATABASE, "transection_requests"));
        const allData = querySnapshot.docs.map((doc, index) => ({
          id: doc.id,
          ...doc.data(),
        }));
        const depositAmount = allData?.filter(doc => doc?.request_type === 0)?.reduce((acc, curr) => {
          const total = acc + curr?.total_amount;
          return total;
        }, 0);
        console.log('depositAmount',depositAmount);
        
        const withdrawAmount = allData?.filter(doc => doc?.request_type === 1)?.reduce((acc, curr) => {
          const total = acc + curr?.withdraw_amount;
          return total;
        }, 0);
        
        setTotalWithdrawAmount(withdrawAmount)
        setTotalDepositAmount(depositAmount || 0);
        setAllTransactionsList(allData || [])
      } catch (error) {
        console.error("Error fetching transactions:", error);
      }
    };
    getTransactionHistory();
    getTotalCustomer();
  }, [])
  return (
    <>
      <Box
        sx={{
          width: "100%",
          bgcolor: "#fff",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography sx={{ fontSize: "24px", fontWeight: "500" }}>
            Dashboard
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              textAlign: "center",
              position: "relative",
            }}
          >
            <Typography sx={{ fontSize: "24px", fontWeight: "500" }}>
              Welcome Admin
            </Typography>
            <Tooltip>
              <IconButton
                size="small"
                sx={{ ml: 2 }}
                aria-haspopup="true"
              >
                <Avatar sx={{ width: 45, height: 45 }}>A</Avatar>
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexWrap: "wrap",
            py: 2,
            gap: "20px",
          }}
        >
          <Box sx={{ width: "300px" }}>
            {isLoading ? <Skeleton height={106} /> : <Box
              sx={{
                width: "100%",
                borderRadius: "12px",
                bgcolor: "white",
                p: 2,
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "start",
                  gap: "10px",
                  alignItems: "center",
                }}
              >
                <GroupsIcon style={{ fontSize: "28px", color: "#fffff" }} />
                <Typography sx={{ fontSize: "20px" }}>Total Users</Typography>
              </Box>
              <Typography sx={{ pt: 1, fontSize: "24px", fontWeight: "500" }}>
                {formatNumber(totalCustomer)}
              </Typography>
            </Box>}
          </Box>
          <Box sx={{ width: "300px" }}>
            {isLoading ? <Skeleton height={106} /> : <Box
              sx={{
                width: "100%",
                borderRadius: "12px",
                bgcolor: "white",
                p: 2,
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "start",
                  gap: "10px",
                  alignItems: "center",
                }}
              >
                <GroupIcon style={{ fontSize: "28px", color: "#fffff" }} />
                <Typography sx={{ fontSize: "20px" }}>
                  Total Corporate Users
                </Typography>
              </Box>
              <Typography sx={{ pt: 1, fontSize: "24px", fontWeight: "500" }}>
                {formatNumber(corporateCustomer)}
              </Typography>
            </Box>}
          </Box>
          <Box sx={{ width: "300px" }}>
            {isLoading ? <Skeleton height={106} /> : <Box
              sx={{
                width: "100%",
                borderRadius: "12px",
                bgcolor: "white",
                p: 2,
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "start",
                  gap: "10px",
                  alignItems: "center",
                }}
              >
                <PersonIcon style={{ fontSize: "28px", color: "#fffff" }} />
                <Typography sx={{ fontSize: "20px" }}>
                  Total Individual Users
                </Typography>
              </Box>
              <Typography sx={{ pt: 1, fontSize: "24px", fontWeight: "500" }}>
                {formatNumber(individualCustomer)}
              </Typography>
            </Box>}
          </Box>
          <Box sx={{ width: "300px" }}>
            {isLoading ? <Skeleton height={106} /> : <Box
              sx={{
                width: "100%",
                borderRadius: "12px",
                bgcolor: "white",
                p: 2,
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "start",
                  gap: "10px",
                  alignItems: "center",
                }}
              >
                <AddCardIcon style={{ fontSize: "28px", color: "#fffff" }} />
                <Typography sx={{ fontSize: "20px" }}>
                  Total Deposits
                </Typography>
              </Box>
              <Typography sx={{ pt: 1, fontSize: "24px", fontWeight: "500" }}>
                {`$${new Intl.NumberFormat('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }).format(totalDepositAmount)}`}
              </Typography>

            </Box>}
          </Box>
          <Box sx={{ width: "300px" }}>
            {isLoading ? <Skeleton height={106} /> : <Box
              sx={{
                width: "100%",
                borderRadius: "12px",
                bgcolor: "white",
                p: 2,
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "start",
                  gap: "10px",
                  alignItems: "center",
                }}
              >
                <WalletIcon style={{ fontSize: "28px", color: "#fffff" }} />
                <Typography sx={{ fontSize: "20px" }}>
                  Total Withdrawals
                </Typography>
              </Box>
              <Typography sx={{ pt: 1, fontSize: "24px", fontWeight: "500" }}>
                {`$${new Intl.NumberFormat('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }).format(totalWithdrawAmount)}`}
              </Typography>
            </Box>}
          </Box>
        </Box>
        <Box sx={{ width: "100%", py: 5, width: "100%", display: "flex", justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
          <Typography sx={{ fontSize: "22px", fontWeight: "500" }}>5-Year Deposit and Withdrawal Chart</Typography>
          <LineChart
            allTransactionsList={allTransactionsList}
          />
        </Box>
      </Box>
    </>
  );
};

export default Dashboard;