import dayjs from "dayjs";
import { Line } from "react-chartjs-2";
const PerformanceDailyGraph = ({ dayWisePerformance }) => {
    const prepareChartData = () => {
        const labels = [];
        const dataPoints = [];
        const selectedMonth = dayWisePerformance?.select_Month;

        if (!selectedMonth) return { labels, dataPoints };

        const month = dayjs(selectedMonth).month();
        const year = dayjs(selectedMonth).year();
        const daysInMonth = new Date(year, month + 1, 0).getDate();
        for (let day = 1; day <= daysInMonth; day++) {
            labels.push(`${day}/${month + 1}/${year}`);
            const dayData = dayWisePerformance?.select_days.find(d => d.day === day);
            dataPoints.push(dayData ? dayData.percentage : 0);
        }

        return { labels, dataPoints };
    };
    const { labels, dataPoints } = prepareChartData();
    const data = {
        labels: labels,
        datasets: [
            {
                label: 'Day Wise Performance (%)',
                data: dataPoints,
                fill: false,
                backgroundColor: 'rgba(12, 44, 80, 0.5)',
                borderColor: 'rgba(12, 44, 80, 1)',
                tension: 0.1,
            },
        ],
    };
    const options = {
        scales: {
            y: {
                beginAtZero: true,
                title: {
                    display: true,
                    text: 'Percentage (%)',
                },
            },
            x: {
                title: {
                    display: true,
                    text: 'Date',
                },
            },
        },
    };
    return (
        <>
            {dataPoints?.some(point => point > 0) ? (
                    <Line data={data} options={options} />
            ) : (
                <p style={{ textAlign: 'center' }}>No data available</p>
            )}
        </>
    );
};

export default PerformanceDailyGraph;
