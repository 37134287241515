import { Navigate } from "react-router-dom";
import AdminLayout from "../Layout/AdminLayout/AdminLayout";
// ==========Auth Routes==========
import Login from '../Pages/Auth/Login/Login';
import ForgotPassword from '../Pages/Auth/ForgotPassword/ForgotPassword';
import ResetPassword from "../Pages/Auth/ResetPassword/ResetPassword";
import Dashboard from "../Pages/Admin/Dashboard/Dashboard";
import AddCustomer from "../Pages/Admin/CustomerManagement/AddCustomer";
import CustomerManagement from "../Pages/Admin/CustomerManagement/CustomerManagement";
import YearlyGraph from "../Pages/Admin/Graph/YearlyGraph/YearlyGraph";
import FundsDeposit from "../Pages/Admin/FundsManagement/FundsDeposit/FundsDeposit";

import TermsAndConditions from "../Pages/Admin/TermsAndConditions/TermsAndConditions";
import PrivacyPolicy from "../Pages/Admin/PrivacyPolicy/PrivacyPolicy";
import AboutUs from "../Pages/Admin/AboutUs/AboutUs";
import ViewCustomer from "../Pages/Admin/CustomerManagement/ViewCustomer";
import WithdrowFunds from "../Pages/Admin/FundsManagement/WithdrowFunds/WithdrowFunds";
import ClosingBalance from "../Pages/Admin/ClosingBalance/ClosingBalance";
import MonthlyGraph from "../Pages/Admin/Graph/MonthlyGraph/MonthlyGraph";
import AddMonthlyGraph from "../Pages/Admin/Graph/MonthlyGraph/AddMonthlyGraph";
// ==========Admin Routes==========

const routes = [
  {
    path: "/",
    component: <Navigate to="/login" component={<Login />} />,
    exact: true,
  },
  {
    path: "/login",
    component: <Login />,
    exact: true,
  },
  {
    path: "/forgot-password",
    component: <ForgotPassword />,
    exact: true,
  },
  {
    path: "/reset-password",
    component: <ResetPassword />,
    exact: true,
  },
  {
    path: "/admin/*",
    component: <AdminLayout />,
    exact: true,
  },
];
export const adminLayoutRoute = [
  {
    path: "/dashboard",
    component: <Dashboard />,
    exact: true,
    sidebar: true,
  },
  {
    path: "/addcustomer",
    component: <AddCustomer />,
    exact: true,
    sidebar: false,
  },
  {
    path: "/editcustomer",
    component: <AddCustomer />,
    exact: true,
    sidebar: false,
  },
  {
    path: "/viewcustomer",
    component: <ViewCustomer />,
    exact: true,
    sidebar: false,
  },
  {
    path: "/customermanagement",
    component: <CustomerManagement />,
    exact: true,
    sidebar: true,
  },
  {
    path: "/monthlygraph",
    component: <MonthlyGraph />,
    exact: true,
    sidebar: true,
  },
  {
    path: "/add-monthly-graph",
    component: <AddMonthlyGraph />,
    exact: true,
    sidebar: false,
  },
  {
    path: "/edit-monthly-graph",
    component: <AddMonthlyGraph />,
    exact: true,
    sidebar: false,
  },
  {
    path: "/yearly-graph",
    component: <YearlyGraph />,
    exact: true,
    sidebar: true,
  },
  {
    path: "/fundsdeposit",
    component: <FundsDeposit />,
    exact: true,
    sidebar: true,
  },
  {
    path: "/closingbalance",
    component: <ClosingBalance />,
    exact: true,
    sidebar: true,
  },
  {
    path: "/withdrawfunds",
    component: <WithdrowFunds />,
    exact: true,
    sidebar: true,
  },
  {
    path: "/termsandconditions",
    component: <TermsAndConditions />,
    exact: true,
    sidebar: true,
  },
  {
    path: "/privacypolicy",
    component: <PrivacyPolicy />,
    exact: true,
    sidebar: true,
  },
  {
    path: "/aboutus",
    component: <AboutUs />,
    exact: true,
    sidebar: true,
  },
];
export default routes;