import React, { Fragment, useEffect, useState } from "react";
import {
    Box,
    Card,
    CardContent,
    Grid,
    IconButton,
    Typography,
    Menu,
    MenuItem,
    Tooltip,
    Chip,
    Button,
} from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { useLocation, useNavigate } from "react-router-dom";
import DUMMYAVTAR from "../../../Assets/Images/Common/avatar-5.jpg";
import moment from "moment";
import TopHeader from "../../../Components/TopHeader/TopHeader";
import { collection, getDocs, orderBy, query, where, onSnapshot, updateDoc, doc } from "firebase/firestore";
import { DATABASE } from "../../../Config/firebase";
import TableContainer from "../../../Components/TableContainer/TableContainer";
import ReceiptIcon from '@mui/icons-material/Receipt';
import NewDepositModel from "../FundsManagement/FundsDeposit/NewDepositModel";
import NewWithdrowModel from "../FundsManagement/WithdrowFunds/NewWithdrowModel";
import { toast } from "react-toastify";
import PersonIcon from '@mui/icons-material/Person';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import Swal from "sweetalert2";
import Skeleton from "react-loading-skeleton";
import dayjs from "dayjs";
const ViewCustomer = () => {
    const [pageSize, setPageSize] = useState(50);
    const navigate = useNavigate();
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(false);
    const [isBusy, setIsBusy] = useState(true);
    const [page, setPage] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [customerDetails, setCustomerDetails] = useState([]);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const [transactionHistory, setTransactionHistory] = useState([]);
    const [lastMonthPerformances, setLastMonthPerformances] = useState([]);
    const handleClick = (event) => setAnchorEl(event.currentTarget)
    const handleClose = () => setAnchorEl(null);
    const [openDepositeModel, setOpenDepositeModel] = useState(false);
    const [openWithdrowModel, setOpenWithdrowModel] = useState(false);
    const [isStatus, setIsStatus] = useState(null);
    const [isActivePageSize, setIsActivePageSize] = useState(null);
    const [totalPerformances, setTotalPerformances] = useState(0)
    const transactionHistoryDepositeCheck = transactionHistory?.filter((elem) =>
        elem?.user_id === customerDetails[0]?.doc_id &&
        elem?.request_type === 0 && elem?.request_status === 2
    );
    const totalDeposit = transactionHistoryDepositeCheck?.reduce((acc, elem) =>
        acc + (elem?.total_amount || 0),
        0
    );
    const transactionHistoryWithdrawCheck = transactionHistory?.filter((elem) =>
        elem?.user_id === customerDetails[0]?.doc_id &&
        elem?.request_type === 1 && elem?.request_status === 5
    );
    const totalWithdraw = transactionHistoryWithdrawCheck?.reduce((acc, elem) =>
        acc + (elem?.total_amount || 0),
        0
    );
    const columns = [
        {
            field: "rowid",
            headerName: "Sr.No",
            minWidth: 100,
            filterable: false,
            editable: false,
            sortable: false,
            disableColumnMenu: true,
        },
        {
            field: "request_type",
            headerName: "Type",
            minWidth: 200,
            filterable: true,
            sortable: true,
            renderCell: (params) => (
                <Tooltip title={params?.row?.request_type || "--"}>
                    <span>{params?.row?.request_type === 0 ? "Deposit" : "withdraw" || "--"}</span>
                </Tooltip>
            ),
        },
        {
            field: "total_amount",
            headerName: "Amount",
            minWidth: 200,
            filterable: false,
            editable: false,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => {
                const balance = params?.row?.total_amount || 0;
                const formattedBalance = new Intl.NumberFormat('en-US', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                }).format(balance);

                return (
                    <Tooltip title={formattedBalance}>
                        <span>{`$${formattedBalance}`}</span>
                    </Tooltip>
                );
            },
        },
        {
            field: "txn_id",
            headerName: "Transaction ID",
            minWidth: 200,
            filterable: false,
            editable: false,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => (
                <Tooltip title={params?.row?.txn_id}>
                    <span>{params?.row?.txn_id}</span>
                </Tooltip>

            ),
        },
        {
            field: "payment_receipt",
            headerName: "Payment Receipt",
            minWidth: 200,
            filterable: false,
            editable: false,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => (
                <Tooltip title={'Download Receipt'}>
                    <IconButton onClick={() => window.open(params?.row?.payment_receipt, '_blank')}>
                        <ReceiptIcon />
                    </IconButton>
                </Tooltip>

            ),
        },
        // {
        //     field: "date",
        //     headerName: "Date",
        //     minWidth: 200,
        //     flex: 1,
        //     filterable: false,
        //     editable: false,
        //     sortable: false,
        //     disableColumnMenu: true,
        //     renderCell: (params) => {
        //         const local = moment(params?.row?.date).utc(params?.row?.date).format('YYYY-MM-DD hh:mm:ss');
        //         return <Tooltip title={local}>
        //             <span>{local}</span>
        //         </Tooltip>
        //     },
        // },
        {
            field: "date",
            headerName: "Registered Date",
            minWidth: 250,
            renderCell: (params) => {
                const { date } = params?.row;

                const seconds = date?.seconds;
                const nanoseconds = date?.nanoseconds;
                const milliseconds = seconds * 1000 + Math.floor(nanoseconds / 1000000);
                const dateT = moment(milliseconds);
                return (
                    <div>
                        {dateT.format('MMMM Do YYYY, h:mm:ss A')}
                    </div>
                );
            },
        },
        {
            field: "request_status",
            headerName: "Status",
            minWidth: 150,
            filterable: false,
            editable: false,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => {
                let status = params?.row?.request_status;
                if (status === 0) {
                    return <Chip label="Pending" sx={{ color: '#fff', backgroundColor: "#FFC107", cursor: 'pointer' }} />;
                } else if (status === 1) {
                    return <Chip label="Waiting" sx={{ color: '#fff', backgroundColor: "#FF9800", cursor: 'pointer' }} />;
                } else if (status === 2) {
                    return <Chip label="Approved" sx={{ color: '#fff', backgroundColor: "#4CAF50", cursor: 'pointer' }} />;
                } else if (status === 3) {
                    return <Chip label="Cancelled" sx={{ color: '#fff', backgroundColor: "#F44336", cursor: 'pointer' }} />;
                } else if (status === 4) {
                    return <Chip label="Rejected" sx={{ color: '#ff', backgroundColor: "#D32F2F", cursor: 'pointer' }} />;
                } else if (status === 5) {
                    return <Chip label="Paid" sx={{
                        background: "#4caf50",
                        color: '#fff',
                        "&:hover": {
                            background: "#388e3c",
                        },
                    }} />
                }
            },
        },
    ];
    useEffect(() => {
        const customData = location?.state?.customData;
        const checkCustomData = location?.state?.typeCheck;
        const pageSizeFromState = location?.state?.page || 0;
        if (customData) {
            setIsActivePageSize(pageSizeFromState)
            const customerID = checkCustomData === "Withdraw" ? customData?.user_id : customData?.doc_id;
            getCustomerDetails(customerID);
        }
    }, [location?.state?.customData]);
    const getCustomerDetails = async (ID) => {
        setIsLoading(true)
        try {
            const transactionRef = collection(DATABASE, "users");
            const q = query(transactionRef, where("doc_id", "==", ID));
            const querySnapshot = await getDocs(q);
            const data = querySnapshot?.docs.map((doc) => doc?.data())
            setCustomerDetails(data)
            return data;
        } catch (error) {
            console.error("Error fetching customer details:", error);
            return [];
        } finally {
            setIsLoading(false)
        }
    };
    useEffect(() => {
        const customData = location?.state?.customData;
        if (customData) {
            const customerID = customData?.doc_id;
            getTransactionHistory(page, pageSize, isStatus, customerID);
        }
    }, [page, pageSize, isStatus, location?.state?.customData]);
    const getTransactionHistory = async (currentPage = 0, pageSize = 50, statusFilter = null, customerID) => {
        setIsBusy(true);
        try {
            const queryRef = collection(DATABASE, "transection_requests");
            let firestoreQuery = query(queryRef);
            if (customerID) {
                firestoreQuery = query(firestoreQuery, where("user_id", "==", customerID));
            }
            if (statusFilter !== null) {
                firestoreQuery = query(firestoreQuery, where("request_type", "==", statusFilter));
            }
            firestoreQuery = query(firestoreQuery, orderBy("date", "desc"));
            const unsubscribe = onSnapshot(firestoreQuery, (querySnapshot) => {
                const allData = querySnapshot?.docs?.map((doc, index) => ({
                    rowid: index + 1,
                    id: doc?.id,
                    ...doc.data(),
                }));
                const lastMonth = dayjs().subtract(1, 'month');

                const lastMonthPerformances = allData?.filter(item => {
                    const itemDate = dayjs(item?.date?.toDate());
                    return itemDate.month() === lastMonth?.month() && item?.request_type === 2;
                });
                const totalPerformance = allData?.reduce((acc, item) => {
                    return acc + (item?.profit_loss || 0);
                }, 0);
    
                setTotalPerformances(totalPerformance)
                setLastMonthPerformances(lastMonthPerformances)
                const filteredData = allData?.filter(item => item?.request_type !== 2);
                setTotalCount(filteredData.length);
                setTransactionHistory(filteredData.slice(currentPage * pageSize, (currentPage + 1) * pageSize));
            });
            return unsubscribe;

        } catch (error) {
            console.error("Error fetching transaction history:", error);
        } finally {
            setIsBusy(false);
        }
    };
    const handleSubmitedDeposit = () => {
        setOpenDepositeModel(false);
        const customerID = location?.state?.customData?.doc_id;
        getTransactionHistory(page, pageSize, isStatus, customerID);
        getCustomerDetails(customerID);
    }
    const handleSubmitedWithdraw = () => {
        setOpenDepositeModel(false);
        const customerID = location?.state?.customData?.doc_id;
        getTransactionHistory(page, pageSize, isStatus, customerID);
        getCustomerDetails(customerID);
    }
    const handlePageChange = (newPage) => {
        setPage(newPage);
        getTransactionHistory(newPage, pageSize, isStatus);
    };
    const handlePageSizeChange = (newPageSize) => {
        setPageSize(newPageSize);
        setPage(0);
        getTransactionHistory(0, newPageSize, isStatus);
    };
    const handleUpdateStatusDepositWithdraw = (id) => {
        setIsStatus(id === null ? null : id);
        setPage(0);
    }
    const handleUpdateBlockUnBlockStatus = async (docId, newStatus) => {
        const result = await Swal.fire({
            title: "Are you sure?",
            text: `You want to ${newStatus === 1 ? "UnBlock" : newStatus === 2 ? "Block" : "Reject"} this customer`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#46adf2",
            cancelButtonColor: "#5b5d5d",
            confirmButtonText: "Yes",
            cancelButtonText: "Cancel",
        });

        if (result.isConfirmed) {
            try {
                await updateDoc(doc(DATABASE, "users", docId), { status: newStatus });
                toast.success(newStatus === 1 ? "UnBlock!" : "Blocked!", `Customer has been ${newStatus === 1 ? "accepted" : "Blocked"}.`, "success");
                const customData = location?.state?.customData;
                getCustomerDetails(customData?.doc_id);
            } catch (error) {
                console.error("Error updating customer status:", error);
                toast.success("Error!", "Something went wrong. Try again!", "error");
            }
        }
    };
    return (
        <Fragment>
            <TopHeader title="View Customer" onClick={() => navigate(-1)} />
            <Box
                sx={{
                    padding: "100px 15px 40px 15px",
                    maxWidth: { xs: "100%" },
                    margin: "0 auto",
                    width: "100%",
                }}
            >
                <Grid container spacing={4}>
                    <Grid item xs={3}>
                        <Card
                            sx={{
                                background: "linear-gradient(148.97deg, #0C2C50 0%, #450F5B 100%)",
                                position: "relative",
                            }}
                        >
                            <CardContent>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12} md={12}>
                                        {isLoading ? <Skeleton height={200} /> : <Box
                                            sx={{
                                                height: "200px",
                                            }}
                                        >
                                            <img
                                                src={
                                                    customerDetails[0]?.profile_img
                                                        ? `${customerDetails[0]?.profile_img}`
                                                        : DUMMYAVTAR
                                                }
                                                alt="customer_Profile"
                                                style={{
                                                    width: "100%",
                                                    height: "100%",
                                                    objectFit: "contain",
                                                    borderRadius: "10px",
                                                    objectPosition: "top",
                                                }}
                                            />
                                        </Box>}
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} sm={12} md={12}>
                                                <Card
                                                    sx={{
                                                        bgcolor: "#fff",
                                                    }}
                                                >
                                                    <CardContent>
                                                        <Typography
                                                            sx={{
                                                                fontSize: "18px",
                                                                fontWeight: "600",
                                                                color: "#000000",
                                                                textAlign: "left",
                                                                width: "100%",
                                                                paddingBottom: "10px",
                                                                fontFamily: "Poppins",
                                                            }}
                                                        >
                                                            Basic information
                                                        </Typography>
                                                        <Grid container spacing={3}>
                                                            <Grid item xs={12} md={12}>
                                                                <Typography
                                                                    sx={{
                                                                        fontSize: "16px",
                                                                        fontWeight: "400",
                                                                        color: "#000000",
                                                                        textAlign: "left",
                                                                        width: "100%",
                                                                        fontFamily: "Poppins",
                                                                    }}
                                                                >
                                                                    Full Name
                                                                </Typography>
                                                                {isLoading ? <Skeleton height={20} /> : <Typography
                                                                    sx={{
                                                                        fontSize: "14px",
                                                                        fontWeight: "400",
                                                                        color: "#000000",
                                                                        textAlign: "left",
                                                                        width: "100%",
                                                                        paddingTop: "4px",
                                                                        textTransform: "capitalize",
                                                                        fontFamily: "Poppins",
                                                                    }}
                                                                >
                                                                    {customerDetails[0]?.full_name
                                                                        ? customerDetails[0]?.full_name
                                                                        : "N/A"}
                                                                </Typography>}
                                                            </Grid>
                                                            <Grid item xs={12} md={12}>
                                                                <Typography
                                                                    sx={{
                                                                        fontSize: "16px",
                                                                        fontWeight: "400",
                                                                        color: "#000000",
                                                                        textAlign: "left",
                                                                        width: "100%",
                                                                        fontFamily: "Poppins",
                                                                    }}
                                                                >
                                                                    Email
                                                                </Typography>
                                                                {isLoading ? <Skeleton height={20} /> : <Typography
                                                                    sx={{
                                                                        fontSize: "14px",
                                                                        fontWeight: "400",
                                                                        color: "#000000",
                                                                        textAlign: "left",
                                                                        width: "100%",
                                                                        paddingTop: "4px",
                                                                        fontFamily: "Poppins",
                                                                    }}
                                                                >
                                                                    {customerDetails[0]?.email
                                                                        ? customerDetails[0]?.email
                                                                        : "N/A"}
                                                                </Typography>}
                                                            </Grid>
                                                            <Grid item xs={12} md={12}>
                                                                <Typography
                                                                    sx={{
                                                                        fontSize: "16px",
                                                                        fontWeight: "400",
                                                                        color: "#000000",
                                                                        textAlign: "left",
                                                                        width: "100%",
                                                                        fontFamily: "Poppins",
                                                                    }}
                                                                >
                                                                    Mobile Number
                                                                </Typography>
                                                                {isLoading ? <Skeleton height={20} /> : <Typography
                                                                    sx={{
                                                                        fontSize: "14px",
                                                                        fontWeight: "400",
                                                                        color: "#000000",
                                                                        textAlign: "left",
                                                                        width: "100%",
                                                                        paddingTop: "4px",
                                                                        textTransform: "capitalize",
                                                                        fontFamily: "Poppins",
                                                                    }}
                                                                >
                                                                    {customerDetails[0]?.phone
                                                                        ? `${customerDetails[0]?.phone?.split(",")[1]} - ${customerDetails[0]?.phone?.split(",")[2]}`
                                                                        : "N/A"}
                                                                </Typography>}
                                                            </Grid>
                                                            <Grid item xs={12} md={12}>
                                                                <Typography
                                                                    sx={{
                                                                        fontSize: "16px",
                                                                        fontWeight: "400",
                                                                        color: "#000000",
                                                                        textAlign: "left",
                                                                        width: "100%",
                                                                        fontFamily: "Poppins",
                                                                    }}
                                                                >
                                                                    User Type
                                                                </Typography>
                                                                {isLoading ? <Skeleton height={20} /> : <Typography
                                                                    sx={{
                                                                        fontSize: "14px",
                                                                        fontWeight: "400",
                                                                        color: "#000000",
                                                                        textAlign: "left",
                                                                        width: "100%",
                                                                        paddingTop: "4px",
                                                                        textTransform: "capitalize",
                                                                        fontFamily: "Poppins",
                                                                    }}
                                                                >
                                                                    {customerDetails[0]?.user_type === 0 
                                                                        ? `Individual Customer`
                                                                        : "Corporate Customer"}
                                                                </Typography>}
                                                            </Grid>
                                                            <Grid item xs={12} md={12}>
                                                                {isLoading ? <Skeleton height={20} /> : <Box sx={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    gridGap: '10px'
                                                                }}>
                                                                    {customerDetails[0]?.status === 1 ? <Tooltip title='Block' key="block">
                                                                        <IconButton
                                                                            sx={{
                                                                                width: "30px",
                                                                                height: "30px",
                                                                                borderRadius: "4px", background: "red", "&:hover": { background: "#C62828" }
                                                                            }}
                                                                            onClick={() => handleUpdateBlockUnBlockStatus(customerDetails[0]?.doc_id, 2)} >
                                                                            <PersonIcon sx={{ color: "#fff" }} />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                        : <Tooltip title='UnBlock' key="UnBlock">
                                                                            <IconButton
                                                                                sx={{
                                                                                    width: "30px",
                                                                                    height: "30px",
                                                                                    borderRadius: "4px", background: "red", "&:hover": { background: "#c62828" }
                                                                                }}
                                                                                onClick={() => handleUpdateBlockUnBlockStatus(customerDetails[0]?.doc_id, 1)}>
                                                                                <PersonOffIcon sx={{ color: "#fff" }} />
                                                                            </IconButton>
                                                                        </Tooltip>}
                                                                </Box>}
                                                            </Grid>
                                                        </Grid>
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={9}>
                        <Grid container spacing={3}>

                            <Grid item xs={12} md={4}>
                                <Typography
                                    sx={{
                                        fontSize: "16px",
                                        fontWeight: "400",
                                        color: "#000000",
                                        textAlign: "left",
                                        width: "100%",
                                        fontFamily: "Poppins",
                                    }}
                                >
                                    Current Balance
                                </Typography>
                                {isLoading ? <Skeleton height={20} /> : <Typography
                                    sx={{
                                        fontSize: "14px",
                                        fontWeight: "400",
                                        color: "#000000",
                                        textAlign: "left",
                                        width: "100%",
                                        paddingTop: "4px",
                                        textTransform: "capitalize",
                                        fontFamily: "Poppins",
                                    }}
                                >
                                    {new Intl.NumberFormat('en-US', {
                                        style: 'currency',
                                        currency: 'USD',
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                    }).format(customerDetails[0]?.net_balance)}
                                </Typography>}
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Typography
                                    sx={{
                                        fontSize: "16px",
                                        fontWeight: "400",
                                        color: "#000000",
                                        textAlign: "left",
                                        width: "100%",
                                        fontFamily: "Poppins",
                                    }}
                                >
                                    Total Deposits
                                </Typography>
                                {isLoading ? <Skeleton height={20} /> : <Typography
                                    sx={{
                                        fontSize: "14px",
                                        fontWeight: "400",
                                        color: "#000000",
                                        textAlign: "left",
                                        width: "100%",
                                        paddingTop: "4px",
                                        fontFamily: "Poppins",
                                    }}
                                >
                                    {new Intl.NumberFormat('en-US', {
                                        style: 'currency',
                                        currency: 'USD',
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                    }).format(totalDeposit)}
                                </Typography>}
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Typography
                                    sx={{
                                        fontSize: "16px",
                                        fontWeight: "400",
                                        color: "#000000",
                                        textAlign: "left",
                                        width: "100%",
                                        fontFamily: "Poppins",
                                    }}
                                >
                                    Total Withdrawals
                                </Typography>
                                {isLoading ? <Skeleton height={20} /> : <Typography
                                    sx={{
                                        fontSize: "14px",
                                        fontWeight: "400",
                                        color: "#000000",
                                        textAlign: "left",
                                        width: "100%",
                                        paddingTop: "4px",
                                        textTransform: "capitalize",
                                        fontFamily: "Poppins",
                                    }}
                                >
                                    {new Intl.NumberFormat('en-US', {
                                        style: 'currency',
                                        currency: 'USD',
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                    }).format(totalWithdraw)}
                                </Typography>}
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Typography
                                    sx={{
                                        fontSize: "16px",
                                        fontWeight: "400",
                                        color: "#000000",
                                        textAlign: "left",
                                        width: "100%",
                                        fontFamily: "Poppins",
                                    }}
                                >
                                    Total Performance
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: "14px",
                                        fontWeight: "400",
                                        color: totalPerformances > 0 ? "green" : "red",
                                        textAlign: "left",
                                        width: "100%",
                                        paddingTop: "4px",
                                        textTransform: "capitalize",
                                        fontFamily: "Poppins",
                                    }}
                                >
                                    {totalPerformances ? new Intl.NumberFormat('en-US', {
                                        style: 'currency',
                                        currency: 'USD',
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                    }).format(totalPerformances) : "--"}
                                </Typography>
                                
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Typography
                                    sx={{
                                        fontSize: "16px",
                                        fontWeight: "400",
                                        color: "#000000",
                                        textAlign: "left",
                                        width: "100%",
                                        fontFamily: "Poppins",
                                    }}
                                >
                                    Last Month's Performance
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: "16px",
                                        fontWeight: "400",
                                        color: "#000000",
                                        textAlign: "left",
                                        width: "100%",
                                        fontFamily: "Poppins",
                                    }}
                                >
                                    {lastMonthPerformances[0]?.profit_loss_percent ? `${lastMonthPerformances[0]?.profit_loss_percent.toFixed(2)} %` : `0 %`}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Box sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: 'flex-end',
                                    gridGap: '10px'
                                }}>
                                    {/* <Button
                                        sx={{
                                            background: "linear-gradient(148.97deg, #0C2C50 0%, #450F5B 100%)",
                                            color: "#fff",
                                            height: '46px',
                                            padding: "0px 20px",
                                            textTransform: 'capitalize',
                                            "&:hover": { background: "linear-gradient(148.97deg, #0C2C50 0%, #450F5B 100%)", color: "#fff" },
                                        }}
                                        onClick={() => setOpenMonthlyGraphModel(true)}
                                    >
                                        Update Monthly Graph
                                    </Button> */}
                                    <Button
                                        sx={{
                                            background: "linear-gradient(148.97deg, #0C2C50 0%, #450F5B 100%)",
                                            color: "#fff",
                                            height: '46px',
                                            padding: "0px 20px",
                                            textTransform: 'capitalize',
                                            "&:hover": { background: "linear-gradient(148.97deg, #0C2C50 0%, #450F5B 100%)", color: "#fff" },
                                        }}
                                        onClick={() => setOpenDepositeModel(true)}
                                    >
                                        + Add Deposit
                                    </Button>
                                    <Button
                                        sx={{
                                            background: "linear-gradient(148.97deg, #0C2C50 0%, #450F5B 100%)",
                                            color: "#fff",
                                            height: '46px',
                                            padding: "0px 20px",
                                            textTransform: 'capitalize',
                                            "&:hover": { background: "linear-gradient(148.97deg, #0C2C50 0%, #450F5B 100%)", color: "#fff" },
                                        }}
                                        onClick={() => {
                                            if (customerDetails[0]?.net_balance === 0) {
                                                toast.error("You don't have sufficient balance.")
                                            } else {
                                                setOpenWithdrowModel(true)
                                            }
                                        }
                                        }
                                    >
                                        + Add Withdraw
                                    </Button>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <Typography
                                        variant="h6"
                                        sx={{
                                            fontSiz: "20px",
                                            color: "#142328",
                                            fontWeight: "500",
                                            fontFamily: "Poppins",
                                        }}
                                    >
                                        Payment Information
                                    </Typography>

                                    <Tooltip title="Filter Payment History">
                                        <IconButton
                                            onClick={handleClick}
                                            size="small"
                                            sx={{ ml: 2 }}
                                            aria-controls={open ? "account-menu" : undefined}
                                            aria-haspopup="true"
                                            aria-expanded={open ? "true" : undefined}
                                        >
                                            <FilterAltIcon
                                                sx={{ width: 40, height: 40, color: "#000" }}
                                            ></FilterAltIcon>
                                        </IconButton>
                                    </Tooltip>
                                    <Menu
                                        anchorEl={anchorEl}
                                        id="account-menu"
                                        open={open}
                                        onClose={handleClose}
                                        onClick={handleClose}
                                        PaperProps={{
                                            elevation: 0,
                                            sx: {
                                                overflow: "visible",
                                                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                                mt: 1.5,
                                                "& .MuiAvatar-root": {
                                                    width: 32,
                                                    height: 32,
                                                    ml: -0.5,
                                                    mr: 1,
                                                },
                                                "&::before": {
                                                    content: '""',
                                                    display: "block",
                                                    position: "absolute",
                                                    top: 0,
                                                    right: 14,
                                                    width: 10,
                                                    height: 10,
                                                    bgcolor: "background.paper",
                                                    transform: "translateY(-50%) rotate(45deg)",
                                                    zIndex: 0,
                                                },
                                            },
                                        }}
                                        transformOrigin={{ horizontal: "right", vertical: "top" }}
                                        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                                    >
                                        <MenuItem
                                            sx={{
                                                fontSize: "18px",
                                                fontWeight: "600",
                                                fontFamily: "Poppins",
                                            }}
                                            onClick={() => handleUpdateStatusDepositWithdraw(null)}
                                        >
                                            All Transaction
                                        </MenuItem>
                                        <MenuItem
                                            sx={{
                                                fontSize: "18px",
                                                fontWeight: "600",
                                                fontFamily: "Poppins",
                                            }}
                                            onClick={() => handleUpdateStatusDepositWithdraw(0)}
                                        >
                                            Deposit
                                        </MenuItem>
                                        <MenuItem

                                            sx={{
                                                fontSize: "18px",
                                                fontWeight: "600",
                                                fontFamily: "Poppins",
                                            }}
                                            onClick={() => handleUpdateStatusDepositWithdraw(1)}
                                        >
                                            Withdraw
                                        </MenuItem>
                                    </Menu>
                                </Box>
                                <Box
                                    sx={{
                                        height: "400px",
                                        overflowY: "auto",
                                    }}
                                >
                                    <TableContainer
                                        rows={transactionHistory}
                                        columns={columns}
                                        loading={isBusy}
                                        page={page}
                                        pageSize={pageSize}
                                        totalCount={totalCount}
                                        handlePageChange={handlePageChange}
                                        onPageSizeChange={handlePageSizeChange}
                                        paginationShow={true}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
            {/* <CustomerMonthlyGraph
                open={openMonthlyGraphModel}
                onClose={() => setOpenMonthlyGraphModel(false)}
                handleOnClose={() => setOpenMonthlyGraphModel(false)}
                monthlyGraphDetails={customerDetails}
                // handleSubmited={handleSubmitedDeposit}
            /> */}
            <NewDepositModel
                open={openDepositeModel}
                onClose={() => setOpenDepositeModel(false)}
                handleOnClose={() => setOpenDepositeModel(false)}
                customerDeposit={customerDetails}
                handleSubmited={handleSubmitedDeposit}
            />
            <NewWithdrowModel
                open={openWithdrowModel}
                onClose={() => setOpenWithdrowModel(false)}
                handleOnClose={() => setOpenWithdrowModel(false)}
                customerWithdraw={customerDetails}
                handleSubmited={handleSubmitedWithdraw}
            />
        </Fragment>
    );
};

export default ViewCustomer;
