export const formatNumber = (num) => {
    if (num >= 1_000_000_000) {
      return (num / 1_000_000_000).toFixed(1) + "B";
    } else if (num >= 1_000_000) {
      return (num / 1_000_000).toFixed(1) + "M";
    } else if (num >= 1_000) {
      return (num / 1_000).toFixed(1) + "K";
    } else {
      return num.toString();
    }
  }
export const formatAmount = (amount) => {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(amount);
};
export const formatBalance = (totalBalance) => {
  if (totalBalance >= 1e9) {
      return `$${(totalBalance / 1e9).toFixed(1)}B`;
  } else if (totalBalance >= 1e6) {
      return `$${(totalBalance / 1e6).toFixed(1)}M`;
  } else if (totalBalance >= 1e3) {
      return `$${(totalBalance / 1e3).toFixed(1)}K`;
  } else {
      return `$${totalBalance.toFixed(2)}`;
  }
}