import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import { Box } from "@mui/material";

const CustomSearch = (props) => {
  const { placeholder, onChange, onClear, value, style={}, sx={}, sx1={} } = props;
  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
      }}
    >
      <input
        autoComplete="off"
        type="text"
        name="focus"
        maxLength="200"
        style={{
          padding: "20px 18px 20px 45px",
          border: "1px solid #CACACA",
          background: "#ffffff",
          borderRadius: "8px",
          width: "100%",
          fontSize: "16px",
          fontWeight: "400",
          color: "#000",
          outline: "none",
          height:"46px",
          fontFamily: "Outfit",
          ...style
        }}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
      />
      {value && value !== "" && (
        <CloseIcon
          sx={{
            right: "10px",
            top: "50%",
            transform: "translateY(-50%)",
            height: "100%",
            fontSize: "22px",
            position: "absolute",
            ...sx
          }}
          onClick={() => {
            onClear && onClear();
          }}
        />
      )}
      <SearchIcon
        sx={{
          left: "10px",
          top: "50%",
          transform: "translateY(-50%)",
          height: "100%",
          fontSize: "28px",
          color: "#737373",
          position: "absolute",
          ...sx1
        }}
      />
      {props.children}
    </Box>
  );
};

export default CustomSearch;
