import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import moment from 'moment';
import React, { Fragment, useEffect, useState } from "react";
import UPLOAD_GALLERY_ICON from "../../../Assets/Images/Common/gallery.png";
import CustomTextField from "../../../Components/CustomTextField/CustomTextField";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import PhoneInput from "react-phone-input-2";
import { useFormik } from "formik";
import * as Yup from "yup";
import CustomDropDown from "../../../Components/CustomDropDown/CustomDropDown";
import {
  updateDoc,
  doc,
  setDoc,
  Timestamp,
} from "firebase/firestore";
import { AUTH_LOGIN, DATABASE, STORAGE } from "../../../Config/firebase";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { getDownloadURL, uploadBytes, ref } from "firebase/storage";
import Skeleton from "react-loading-skeleton";
import TopHeader from "../../../Components/TopHeader/TopHeader";
import DOCUMENT_ICON from '../../../Assets/Images/Common/documentation.png';
const AddCustomer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [customerDetails, setCustomerDetails] = useState({});
  const [mediaTypes, setMediaTypes] = useState(null);

  const [mediaUploads, setMediaUploads] = useState(null);
  const [mediaPreview, setMediaPreview] = useState(null);
  const [coverImageUploadPreview, setCoverImageUploadPreview] = useState(null);
  const [coverImageUploadFile, setCoverImageUploadFile] = useState(null);
  const documentTypesList = [
    {
      id: 1,
      name: "Passport",
      label: "Passport"
    },
    {
      id: 2,
      name: "ID-Card",
      label: "ID-Card"
    },
  ];
  const userTypesList = [
    { id: 0, name: "Individual Customer", label: "Individual Customer", ids: 0 },
    { id: 1, name: "Corporate Customer", label: "Corporate Customer", ids: 1 },
  ];
  const handleUploadMedia = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size <= 5 * 1024 * 1024) {
        const fileType = file.type.split('/')[0];
        const acceptedDocumentTypes = [
          'application/pdf',
          'application/msword',
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        ];
        if (fileType === 'image') {
          setMediaUploads(file);
          setMediaTypes('image');
          setMediaPreview(URL.createObjectURL(file));
          formik.setFieldValue('document', file);
        } else if (acceptedDocumentTypes.includes(file.type)) {
          setMediaUploads(file);
          setMediaTypes('document');
          setMediaPreview(null);
          formik.setFieldValue('document', file);
        } else {
          toast.error(`Invalid file type. Please upload an image or a document.`);
        }
      } else {
        toast.error(`File size exceeds the limit of 5MB`);
      }
    }
  };
  const handleCoverImageOnChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size <= 5 * 1024 * 1024) {
        const imageUrl = URL.createObjectURL(file);
        setCoverImageUploadFile(file);
        setCoverImageUploadPreview(imageUrl);
        formik.setFieldValue('profile', file);
      } else {
        toast.error(`File size exceeds the limit of 5MB`);
      }
    }
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      fullName: (customerDetails && customerDetails?.doc_id) ? customerDetails?.full_name : "",
      phone: (customerDetails && customerDetails?.doc_id) ? customerDetails?.phone : "",
      email: (customerDetails && customerDetails?.doc_id) ? customerDetails?.email : "",
      password: (customerDetails && customerDetails?.doc_id) ? customerDetails?.password : "",
      document: (customerDetails && customerDetails?.doc_id) ? customerDetails?.document : null,
      documentType: (customerDetails && customerDetails?.doc_id) ? customerDetails?.document_type : null,
      userType: (customerDetails && customerDetails?.doc_id) ? customerDetails?.user_type : null,
      documentNumber: (customerDetails && customerDetails?.doc_id) ? customerDetails?.document_number : "",
      profile: (customerDetails && customerDetails?.doc_id) ? customerDetails?.profile_image : null,
    },
    validationSchema: Yup.object({
      fullName: Yup.string().required("FullName is required"),
      phone: Yup.string().required("Phone number is required"),
      email: Yup.lazy(value =>
        !(customerDetails?.doc_id)
          ? Yup.string().email("Invalid email address").required("Email is required")
          : Yup.string().nullable()
      ),
      password: Yup.lazy(value =>
        !(customerDetails?.doc_id)
          ? Yup.string().required("Password is required").matches(
            /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
            "Password must contain at least one letter, one number, and one symbol. Minimum length is 8 characters."
          )
          : Yup.string().nullable()
      ),
      documentNumber: Yup.string().required("Document number is required"),
      documentType: Yup.mixed()
        .test("is-not-zero", "Document type is required", value => value !== null)
        .required("Document type is required!"),
      userType: Yup.mixed()
        .test("is-not-zero", "Document type is required", value => value !== null)
        .required("User type is required!"),
      document: Yup.mixed().required("Document type is required"),
      profile: Yup.mixed().required("Customer profile is required"),

    }),
    onSubmit: async (values, { resetForm }) => {
      setIsLoading(true);
      if (isLoading) {
        return;
      } else {
        if (customerDetails && customerDetails?.doc_id) {
          let imageDownloadURL = null;
          if (mediaUploads) {
            const imageRef = ref(STORAGE, `Images/userDoc/${customerDetails?.doc_id}`);
            await uploadBytes(imageRef, values?.document);
            imageDownloadURL = await getDownloadURL(imageRef);
          }
          let customerProfile = null;
          if (coverImageUploadFile) {
            const imageRef = ref(STORAGE, `Images/profile/${customerDetails?.doc_id}`);
            await uploadBytes(imageRef, values?.profile);
            customerProfile = await getDownloadURL(imageRef);
          }
          const userData = {
            doc_id: customerDetails?.doc_id,
            fcm: "",
            document_number: values?.documentNumber,
            document_type: values?.documentType,
            full_name: values?.fullName,
            net_balance: customerDetails?.net_balance,
            registered_date: Timestamp.now(),
            role: customerDetails?.role,
            status: customerDetails?.status,
            user_type: values?.userType,
            phone: values?.phone,
            ...(coverImageUploadFile && { profile_image: customerProfile }),
            ...(mediaUploads && { document: imageDownloadURL }),
            ...(mediaUploads && { mediaType: mediaTypes })
          }
          const response = await updateDoc(doc(DATABASE, "users", customerDetails?.doc_id), userData);
          if (response === undefined) {
            toast.success("Customer updated successfully!");
            navigate(`/admin/customermanagement`);
          } else {
            console.error("Update response:", response);
            toast.error("Update failed.");
          }
        } else {
          try {
            const response = await createUserWithEmailAndPassword(AUTH_LOGIN, values?.email, values?.password);
            if (response && response?.user && response?.user?.uid) {
              let imageDownloadURL = null;
              if (mediaUploads) {
                const imageRef = ref(STORAGE, `Images/userDoc/${response?.user?.uid}`);
                await uploadBytes(imageRef, values.document);
                imageDownloadURL = await getDownloadURL(imageRef);
              }
              let customerProfile = null;
              if (coverImageUploadFile) {
                const imageRef = ref(STORAGE, `Images/profile/${response?.user?.uid}`);
                await uploadBytes(imageRef, values?.profile);
                customerProfile = await getDownloadURL(imageRef);
              }
              const userData = {
                doc_id: response?.user?.uid,
                fcm: "",
                document: imageDownloadURL,
                document_number: values?.documentNumber,
                document_type: values?.documentType,
                email: values?.email,
                full_name: values?.fullName,
                net_balance: 0,
                profile_image: customerProfile,
                registered_date: Timestamp.now(),
                role: 1,
                status: 1,
                phone: values?.phone,
                mediaType: mediaTypes,
                user_type: values?.userType
              }
              const response1 = await setDoc(doc(DATABASE, "users", response?.user?.uid), userData);
              if (response1 === undefined) {
                toast.success("Customer registered successfully!");
                navigate(`/admin/customermanagement`);
                resetForm();
                setMediaPreview(null)
                setMediaTypes(null)
                setMediaUploads(null)
              }
            }
          } catch (error) {
            console.log('error', error);
            if (error.code === 'auth/email-already-in-use') {
              toast.error("Customer already exists.");
            } else {
              toast.error("Customer registration failed.");
            }
          } finally {
            setIsLoading(false);
          }
        }
      }
    },
  })
  
  useEffect(() => {
    const customData = location?.state?.customData;
    if (customData?.doc_id) {
      setCustomerDetails(customData);
      if (customData?.document) {
        setMediaPreview(customData?.document);
        setMediaTypes(customData?.mediaType);
      }
      if (customData?.profile_image) {
        setCoverImageUploadPreview(customData?.profile_image);
        formik.setFieldValue('profile', customData?.profile_image);
      } else {
        setCoverImageUploadPreview(null);
        setCoverImageUploadFile(null);
      }
    }
  }, [location?.state?.customData]);
  return (
    <>
      <Fragment>
        <TopHeader
          title={customerDetails?.doc_id ? "Update Customer" : "Add Customer"}
          onClick={() => navigate(-1)}
          type="button"
          btnTitle="Submit"
          onSubmit={formik.handleSubmit}
          loading={isLoading}
          showButton={true}
        />
        <Box sx={{
          width: "100%",
          bgcolor: "#fff",
          padding: "40px 15px 40px 15px",
        }}>
          <Container>
            <Box
              sx={{
                width: "100%",
                p: 3,
                mt: 5,
                boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                borderRadius: "12px",
              }}
            >
              <form
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  gap: "40px",
                }} onSubmit={(e) => {
                  e.preventDefault();
                  formik.handleSubmit();
                  return false;
                }}
              >
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    {isLoading ? (
                      <Skeleton height={200} />
                    ) : (<Box
                      sx={{
                        width: "400px",
                        margin: '0 auto',
                        height: "200px",
                        overflow: "hidden",
                        background: "#fff",
                        position: "relative",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "12px",
                        border: '1px solid #CACACA'
                      }}
                    >
                      {!coverImageUploadPreview && (
                        <label htmlFor="cover-upload">
                          <Button
                            component="span"
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              flexDirection: "column",
                              outline: "none",
                              border: "none",
                              backgroundColor: "transparent",
                              textTransform: "capitalize",
                            }}
                            type="button"
                          >
                            <img
                              src={UPLOAD_GALLERY_ICON}
                              alt="Upload Icon"
                              effect="blur"
                              width={"42px"}
                            />
                            <Typography
                              variant="body2"
                              sx={{
                                fontSize: "14px",
                                fontWeight: "600",
                                color: "#000000",
                                margin: "8px 0px !important",
                              }}
                            >
                              Upload Profile
                            </Typography>
                            <Typography
                              variant="body2"
                              sx={{
                                fontSize: "14px",
                                fontWeight: "600",
                                color: "#9C9C9C",
                              }}
                            >
                              {`(JPG,JPEG,PNG)`}
                            </Typography>
                            <input
                              accept="image/*"
                              style={{ display: "none" }}
                              id="cover-upload"
                              type="file"
                              onChange={handleCoverImageOnChange}
                            />
                          </Button>
                        </label>
                      )}
                      {coverImageUploadPreview && (
                        <Box>
                          <img
                            src={coverImageUploadPreview}
                            alt="Uploaded Image"
                            effect="blur"
                            height={"150px"}
                            width={"150px"}
                          />
                          <label htmlFor="cover-upload">
                            <IconButton
                              component="span"
                              sx={{
                                width: "40px",
                                height: "40px",
                                borderRadius: "16px",
                                backgroundColor: "#ffffff",
                                boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                                transition: "0.5s",
                                position: "absolute",
                                right: "5px",
                                top: "5px",
                                "&:hover": {
                                  backgroundColor: "#ffffff",
                                  boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                                },
                              }}
                            >
                              <EditIcon
                                sx={{
                                  color: "#000",
                                  fontSize: "26px",
                                }}
                              />
                            </IconButton>
                            <input
                              accept="image/*"
                              style={{ display: "none" }}
                              id="cover-upload"
                              type="file"
                              onChange={handleCoverImageOnChange}
                            />
                            <IconButton
                              component="span"
                              sx={{
                                width: "40px",
                                height: "40px",
                                borderRadius: "16px",
                                backgroundColor: "#ffffff",
                                boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                                transition: "0.5s",
                                position: "absolute",
                                right: "50px",
                                top: "5px",
                                "&:hover": {
                                  backgroundColor: "#ffffff",
                                  boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                                },
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                setCoverImageUploadPreview(null);
                                setCoverImageUploadFile(null);
                                formik.setFieldValue('profile', null)
                              }}
                            >
                              <CloseIcon
                                sx={{
                                  color: "#000",
                                  fontSize: "26px",
                                }}
                              />
                            </IconButton>
                          </label>
                        </Box>
                      )}
                    </Box>)}
                    {formik.touched.profile && formik.errors.profile ? (
                      <Typography
                        sx={{
                          color: "red",
                          fontWeight: "400",
                          fontSize: { xs: "14px", sm: "14px" },
                          textAlign: "center",
                          mt: '4px'
                        }}
                      >
                        {formik.errors.profile}
                      </Typography>
                    ) : null}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <CustomTextField
                      placeholder={"Enter  Name"}
                      style={{ color: "black", fontSize: "16px" }}
                      label={"Name of customer"}
                      name='fullName'
                      sx={{
                        mt: 0,
                        "& .MuiOutlinedInput-notchedOutline ": {
                          outline: "none",
                          border: "1px solid #CACACA",
                        },
                        "& .MuiInputBase-root": {
                          borderRadius: "12px",
                          border: "none",
                          color: "black",
                          fontSize: "16px",
                          background: "white",
                          fontFamily: "Outfit",
                        },
                      }}
                      value={formik.values.fullName}
                      onBlur={formik.handleBlur}
                      onKeyUp={formik.handleBlur}
                      isLoading={isLoading}
                      errors={formik.touched.fullName && formik.errors.fullName}
                      onChange={(e) => {
                        const { value } = e.target;
                        if (value.trimStart() !== value) {
                          e.target.value = value.trimStart();
                        }
                        formik.handleChange(e);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography sx={{ mb: '8px' }}>Mobile Number</Typography>
                    {isLoading ? (
                      <Skeleton height={60} />
                    ) : (<PhoneInput
                      country={"us"}
                      name="phno"
                      placeholder="Enter Mobile Number"
                      value={formik.values.phone}
                      onChange={(value, country) => {
                        const countryCode = country.dialCode;
                        const countryCodeLength = countryCode.length;
                        const phno = value.slice(countryCodeLength);

                        formik.setFieldValue("phone", `${country.countryCode},+${countryCode},${phno}`);
                      }}

                      isLoading={isLoading}
                      inputClass="inputClassCountry"
                    />)}
                    {formik.touched.phone && formik.errors.phone ? (
                      <Typography
                        sx={{
                          color: "red",
                          fontWeight: "400",
                          fontSize: { xs: "14px", sm: "14px" },
                          marginTop: "5px",
                        }}
                      >
                        {formik.errors.phone}
                      </Typography>
                    ) : null}
                  </Grid>
                  {!customerDetails?.doc_id && <Grid item xs={12} md={6}>
                    <CustomTextField
                      placeholder={"Email Address"}
                      style={{ color: "black", fontSize: "16px" }}
                      label={"Email Address"}
                      name='email'
                      sx={{
                        mt: 0,
                        "& .MuiOutlinedInput-notchedOutline ": {
                          outline: "none",
                          border: "1px solid #CACACA",
                        },
                        "& .MuiInputBase-root": {
                          borderRadius: "12px",
                          border: "none",
                          color: "black",
                          fontSize: "16px",
                          background: "white",
                          fontFamily: "Outfit",
                        },
                      }}
                      isLoading={isLoading}
                      value={formik.values.email}
                      onBlur={formik.handleBlur}
                      onKeyUp={formik.handleBlur}
                      errors={formik.touched.email && formik.errors.email}
                      onChange={(e) => {
                        const { value } = e.target;
                        if (value.trimStart() !== value) {
                          e.target.value = value.trimStart();
                        }
                        formik.handleChange(e);
                      }}
                    />
                  </Grid>}
                  {!customerDetails?.doc_id && <Grid item xs={12} md={6}>
                    <CustomTextField
                      placeholder={"Enter Password"}
                      style={{ color: "black", fontSize: "16px" }}
                      label={"Password"}
                      type={'password'}
                      name={'password'}
                      sx={{
                        mt: 0,
                        "& .MuiOutlinedInput-notchedOutline ": {
                          outline: "none",
                          border: "1px solid #CACACA",
                        },
                        "& .MuiInputBase-root": {
                          borderRadius: "12px",
                          border: "none",
                          color: "black",
                          fontSize: "16px",
                          background: "white",
                          fontFamily: "Outfit",
                        },
                      }}
                      isLoading={isLoading}
                      value={formik.values.password}
                      onBlur={formik.handleBlur}
                      onKeyUp={formik.handleBlur}
                      errors={formik.touched.password && formik.errors.password}
                      onChange={(e) => {
                        const { value } = e.target;
                        if (value.trimStart() !== value) {
                          e.target.value = value.trimStart();
                        }
                        formik.handleChange(e);
                      }}
                    />

                  </Grid>}
                  <Grid item xs={12} md={4}>
                    <CustomDropDown
                      Title={"Document Type"}
                      sx={{ width: "100%" }}
                      placeholder={"Select Document Type"}
                      onChange={formik.handleChange}
                      menuList={documentTypesList}
                      NoData='No Plan Type Available'
                      isLoading={isLoading}
                      name={'documentType'}
                      getLabel={(item) => item}
                      value={formik.values.documentType}
                      errors={formik.touched.documentType && formik.errors.documentType}
                      style={{
                        mb: "8px"
                      }}
                    />

                  </Grid>
                  <Grid item xs={12} md={4}>
                    <CustomDropDown
                      Title={"User Type"}
                      sx={{ width: "100%" }}
                      placeholder={"Select User Type"}
                      onChange={formik.handleChange}
                      menuList={userTypesList}
                      NoData='No User Type Available'
                      isLoading={isLoading}
                      name={'userType'}
                      getLabel={(item) => item}
                      value={formik.values.userType}
                      errors={formik.touched.userType && formik.errors.userType}
                      style={{
                        mb: "8px"
                      }}
                    />

                  </Grid>
                  <Grid item xs={12} md={4}>
                    <CustomTextField
                      placeholder={"Enter Document Number"}
                      style={{ color: "black", fontSize: "16px" }}
                      label={"Document Number"}
                      name={'documentNumber'}
                      sx={{
                        mt: 0,
                        "& .MuiOutlinedInput-notchedOutline ": {
                          outline: "none",
                          border: "1px solid #CACACA",
                        },
                        "& .MuiInputBase-root": {
                          borderRadius: "12px",
                          border: "none",
                          color: "black",
                          fontSize: "16px",
                          background: "white",
                          fontFamily: "Outfit",
                        },
                      }}
                      isLoading={isLoading}
                      value={formik.values.documentNumber}
                      onBlur={formik.handleBlur}
                      onKeyUp={formik.handleBlur}
                      errors={formik.touched.documentNumber && formik.errors.documentNumber}
                      onChange={(e) => {
                        const { value } = e.target;
                        if (value.trimStart() !== value) {
                          e.target.value = value.trimStart();
                        }
                        formik.handleChange(e);
                      }}
                    />

                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Box
                      sx={{
                        width: { xs: "100%", md: "100%", lg: "400px" },
                        display: "flex",
                        gap: "20px",
                        flexDirection: { xs: "column" },
                        margin: '0 auto',
                      }}
                    >
                      <Typography
                        sx={{
                          color: "black",
                          fontWeight: "400",
                          textAlign: "center",
                          fontSize: { xs: "14px", sm: "16px" },
                        }}
                      >
                        Document Image
                      </Typography>
                      {isLoading ? (
                        <Skeleton height={200} />
                      ) : (
                        <>
                          <Box
                            sx={{
                              width: "100%",
                              margin: '0 auto',
                              height: "200px",
                              overflow: "hidden",
                              background: "#fff",
                              position: "relative",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: "10px",
                              border: "1px solid #CACACA",
                            }}
                          >
                            {!mediaTypes ? (
                              <label htmlFor="document">
                                <Button
                                  component="span"
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "column",
                                    outline: "none",
                                    border: "none",
                                    backgroundColor: "transparent",
                                    textTransform: "capitalize",
                                  }}
                                  type="button"
                                >
                                  <img src={UPLOAD_GALLERY_ICON} alt="Upload Icon" width={"42px"} />
                                  <Typography
                                    variant="body2"
                                    sx={{
                                      fontSize: "14px",
                                      fontWeight: "600",
                                      color: "#000000",
                                      margin: "8px 0px !important",
                                    }}
                                  >
                                    Upload ID Card
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    sx={{
                                      fontSize: "14px",
                                      fontWeight: "600",
                                      color: "#9C9C9C",
                                    }}
                                  >
                                    {`(PSD, DOC, PDF, JPG, PNG)`}
                                  </Typography>
                                  <input
                                    accept="image/*,.pdf,.doc,.docx,.psd"
                                    style={{ display: "none" }}
                                    id="document"
                                    type="file"
                                    onChange={handleUploadMedia}
                                  />
                                </Button>
                              </label>
                            ) : (
                              <Box sx={{ textAlign: "center" }}>
                                {mediaTypes === 'image' ? (
                                  <img
                                    src={mediaPreview}
                                    alt="Uploaded"
                                    style={{ width: "100%", height: "100%" }}
                                  />
                                ) : (
                                  <>
                                    <img src={DOCUMENT_ICON} alt="document" style={{ width: "100px" }} />
                                    <Typography
                                      variant="body2"
                                      sx={{
                                        fontSize: "14px",
                                        fontWeight: "600",
                                        color: "#9C9C9C",
                                        padding: "5px 0px",
                                      }}
                                    >
                                      Document Uploaded
                                    </Typography>
                                  </>
                                )}
                                <label htmlFor="document">
                                  <IconButton
                                    component="span"
                                    sx={{
                                      width: "40px",
                                      height: "40px",
                                      borderRadius: "16px",
                                      backgroundColor: "#ffffff",
                                      boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                                      transition: "0.5s",
                                      position: "absolute",
                                      right: "5px",
                                      top: "5px",
                                      "&:hover": {
                                        backgroundColor: "#ffffff",
                                      }
                                    }}
                                  >
                                    <EditIcon sx={{ color: "#000", fontSize: "26px" }} />
                                  </IconButton>
                                  <input
                                    accept="image/*,.pdf,.doc,.docx,.psd"
                                    style={{ display: "none" }}
                                    id="document"
                                    type="file"
                                    onChange={handleUploadMedia}
                                  />
                                  <IconButton
                                    component="span"
                                    sx={{
                                      width: "40px",
                                      height: "40px",
                                      borderRadius: "16px",
                                      backgroundColor: "#ffffff",
                                      boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                                      transition: "0.5s",
                                      position: "absolute",
                                      right: "50px",
                                      top: "5px",
                                      "&:hover": {
                                        backgroundColor: "#ffffff",
                                      }
                                    }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setMediaUploads(null);
                                      setMediaPreview(null);
                                      setMediaTypes(null);
                                      formik.setFieldValue('document', null);
                                    }}
                                  >
                                    <CloseIcon sx={{ color: "#000", fontSize: "26px" }} />
                                  </IconButton>
                                </label>
                              </Box>
                            )}
                          </Box>
                          {formik.touched.document && formik.errors.document ? (
                            <Typography
                              sx={{
                                color: "red",
                                fontWeight: "400",
                                fontSize: { xs: "14px", sm: "14px" },
                              }}
                            >
                              {formik.errors.document}
                            </Typography>
                          ) : null}
                        </>
                      )}
                    </Box>

                  </Grid>
                </Grid>
              </form>
            </Box>
          </Container>
        </Box>
      </Fragment>
    </>
  );
};

export default AddCustomer;
