import { Box, Button, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import TableContainer from "../../../../Components/TableContainer/TableContainer";
import { useNavigate } from "react-router-dom";
import { collection, getDocs, onSnapshot, query } from "firebase/firestore";
import { DATABASE } from "../../../../Config/firebase";
import useDebounce from "../../../../Components/useDebounce/useDebounce";
import moment from "moment";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import BarChartIcon from '@mui/icons-material/BarChart';
import CustomSearch from '../../../../Components/CustomSearch/CustomSearch';
import ShowYearlyGraph from "./ShowYearlyGraph";
const YearlyGraph = () => {
    const navigate = useNavigate()
    const [pageSize, setPageSize] = useState(50);
    const [page, setPage] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [monthlyGraphList, setMonthlyGraphList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [searchCustmer, setSearchCustmer] = useState("");
    const debouncedSearch = useDebounce(searchCustmer, 500);
    const [performanceShowModel, setPerformanceShowModel] = useState(false);
    const [performanceGraphDetails, setPerformanceGraphDetails] = useState({});
    const columns = [
        {
            field: "rowid", headerName: "Sr.No.", minWidth: 100,
            renderCell: (params) => {
                return (
                    <Typography sx={{
                        fontSize: "14px",
                        fontWeight: "400",
                    }}>
                        {params?.row?.rowid}
                    </Typography>
                )
            }
        },
        {
            field: "user_name",
            headerName: "Name",
            minWidth: 200,
            renderCell: (params) => {

                return (
                    <Typography sx={{
                        fontSize: "14px",
                        fontWeight: "400",
                    }}>
                        {params?.row?.user_name}
                    </Typography>
                )
            }
        },
        {
            field: "email",
            headerName: "Email",
            minWidth: 250,
            flex:1,
            renderCell: (params) => {
                return (
                    <Typography sx={{
                        fontSize: "14px",
                        fontWeight: "400",
                    }}>
                        {params?.row?.email}
                    </Typography>
                )
            }
        },
        {
            field: "graph_points",
            headerName: "Graph",
            minWidth: 20,
            renderCell: (params) => {
                return (
                    <Typography
                        sx={{
                            fontSize: "14px",
                            fontWeight: "400",
                        }} onClick={() => handleShowHideMonthlyGraph(params?.row)}>
                        <BarChartIcon />
                    </Typography>
                );
            },
        },
    ];
    useEffect(() => {
        getMonthlyList(page, pageSize, debouncedSearch);
    }, [page, debouncedSearch]);
    const getMonthlyList = async (currentPage = 0, pageSize = 50, debouncedSearch = "") => {
        setIsLoading(true);
        let q = query(collection(DATABASE, "yearly_graph"));
        const unsubscribe = onSnapshot(q, async (querySnapshot) => {
            const transactions = querySnapshot.docs.map((doc, index) => ({
                rowid: index + 1,
                id: doc.id,
                ...doc.data(),
            }));
            const usersSnapshot = await getDocs(collection(DATABASE, "users"));
            const usersList = usersSnapshot.docs.map(userDoc => ({
                doc_id: userDoc?.id,
                email: userDoc?.data()?.email,
                registered_date: userDoc?.data().registered_date,
                name: userDoc?.data()?.full_name,
                ...userDoc.data(),
            }));
            const monthlyWithUserNames = transactions.map(elem => {
                const matchedUser = usersList.find(cust => cust?.doc_id === elem?.user_id);
                return {
                    ...elem,
                    user_name: matchedUser?.full_name || "Unknown",
                    email: matchedUser?.email || "--",
                    registered_date: matchedUser?.registered_date || "--",
                };
            });
            const filteredMonthly = debouncedSearch
                ? monthlyWithUserNames.filter(elem =>
                    elem?.email.toLowerCase().includes(debouncedSearch.toLowerCase()) ||
                    elem?.user_name.toLowerCase().includes(debouncedSearch.toLowerCase())
                )
                : monthlyWithUserNames;
            const paginatedMonthly = filteredMonthly.slice(currentPage * pageSize, (currentPage + 1) * pageSize);
            setMonthlyGraphList(paginatedMonthly);
            setTotalCount(filteredMonthly?.length);
            setIsLoading(false);

        }, (error) => {
            console.error("Error fetching deposits:", error);
            setIsLoading(false);
        });
        return unsubscribe;
    }
    const handlePageSizeChange = (newPageSize) => {
        setPageSize(newPageSize);
        setPage(0);
        getMonthlyList(0, newPageSize, debouncedSearch);
    };
    const handlePageChange = (newPage) => {
        setPage(newPage);
        getMonthlyList(newPage, pageSize, debouncedSearch);
    };
    const handleShowHideMonthlyGraph = (graphPoints) => {
        setPerformanceShowModel(true);
        setPerformanceGraphDetails(graphPoints);
    }
    return (
        <>
            <Box sx={{
                width: "100%"
            }}>
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <Typography sx={{ fontSize: "24px", fontWeight: "500" }}>
                            Yealry Graph Details
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <CustomSearch
                            placeholder="Search Customer (Email, Full Name, Ledger)"
                            value={searchCustmer}
                            onChange={(event) => setSearchCustmer(event.target.value)}
                            onClear={() => setSearchCustmer("")} />
                    </Grid>
                    <Grid item xs={12}>
                        <TableContainer
                            rows={monthlyGraphList}
                            columns={columns}
                            loading={isLoading}
                            page={page}
                            pageSize={pageSize}
                            totalCount={totalCount}
                            handlePageChange={handlePageChange}
                            onPageSizeChange={handlePageSizeChange}
                            paginationShow={true}
                        />
                    </Grid>
                </Grid>
            </Box>
            {performanceShowModel && <ShowYearlyGraph
                open={performanceShowModel}
                onClose={() => setPerformanceShowModel(false)}
                handleOnClose={() => setPerformanceShowModel(false)}
                performanceGraphDetails={performanceGraphDetails}
            />}

        </>
    );
};

export default YearlyGraph;
