import { Box, Grid, TablePagination } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React from "react";
import { styled } from "@mui/system";
import NoDataImg from "../NoDataFound/NoDataFound";

const LeftAlignedCell = styled("div")(({ theme }) => ({
  textAlign: "left",
}));

const TableContainer = ({
  rows,
  columns,
  pageSize,
  loading,
  page,
  handlePageChange,
  totalCount,
  sx,
  SXHEAD,
  onPageSizeChange,
  paginationShow
}) => {
  const centeredColumns = columns?.map((column) => ({
    ...column,
    headerAlign: column.headerAlign ?? "center",
    cellClassName: column.cellClassName || "centered-cell",
  }));

  return (
    <Box sx={{ width: "100%", overflow: "auto", borderRadius: '10px' }}>
      <Grid container justifyContent="center">
        <Grid item xs={12} sx={{ height: "calc(100vh - 220px)", ...sx }}>
          <DataGrid
            rows={rows}
            columns={centeredColumns}
            pageSize={pageSize}
            pagination
            paginationMode="server"
            onPageChange={(newPage) => handlePageChange(newPage)}
            rowCount={totalCount}
            loading={loading}
            // disableColumnResize
            // disableColumnSelector
            // disableColumnSorting
            // disableColumnMenu
            hideFooter
            components={{ NoRowsOverlay: NoDataImg }}
            disableRowSelectionOnClick
            sx={{
              "& .MuiDataGrid-columnHeader": {
                background: "linear-gradient(148.97deg, #0C2C50 0%, #450F5B 100%)",
                fontFamily: "Outfit",
                color: "#fff",
                textAlign: "center",
                ...SXHEAD
              },
              "& .MuiDataGrid-columnHeaderIcon": {
                color: "#fff",  // Set header icon color (filter and sort) to white
              },
              "& .MuiDataGrid-sortIcon": {
                color: "#fff",  // Ensure sort icon color is white
              },
              "& .MuiDataGrid-MoreVert": {
                color: "#fff !important",  // Ensure menu icon color (like filter) is white
              },
              "& .MuiDataGrid-cell": {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                border: "1px solid #0000003D",
                fontFamily: "Outfit",
              },
              "& .MuiDataGrid-row": {
                cursor: "pointer",
              },
              "& .leftAlign": {
                justifyContent: "flex-start",
              },
              ".MuiDataGrid-cell:focus": { outline: "none" },
              ".MuiDataGrid-columnHeader:focus": { outline: "none" },
            }}
          />
        </Grid>
       {paginationShow && 
       <Grid item xs={12}>
          <TablePagination
            rowsPerPageOptions={[10, 20, 50, 100]}
            component="div"
            count={totalCount}
            rowsPerPage={pageSize}
            page={page}
            onPageChange={(event, newPage) => handlePageChange(newPage)}
            onRowsPerPageChange={(event) => {
              const newPageSize = parseInt(event.target.value, 10);
              onPageSizeChange(newPageSize);
            }}
          />
        </Grid>}
      </Grid>
    </Box>
  );
};

export default TableContainer;
