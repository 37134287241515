import React from 'react';
import { Line } from 'react-chartjs-2';
import { Dialog } from '@mui/material';
import {
    Chart as ChartJS,
    LineElement,
    PointElement,
    LineController,
    CategoryScale,
    LinearScale,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

ChartJS.register(LineElement, PointElement, LineController, CategoryScale, LinearScale, Title, Tooltip, Legend);

const ShowYearlyGraph = ({ performanceGraphDetails, open, onClose }) => {
    if (!open) return null;

    const graphPoints = performanceGraphDetails.graph_points;
    if (!Array.isArray(graphPoints) || graphPoints.length === 0) {
        console.error("No graph points available");
        return null;
    }

    // Reverse the graph points array to get months in correct left-to-right order
    const reversedGraphPoints = [...graphPoints].reverse();

    const labels = reversedGraphPoints.map(point => {
        const monthIndex = point.month - 1;
        return new Date(2024, monthIndex).toLocaleString('default', { month: 'long' });
    });

    const data = {
        labels: labels,
        datasets: [
            {
                label: 'Performance Percentage',
                data: reversedGraphPoints.map(point => {
                    const percentageValue = parseFloat(point.percentage);
                    return isNaN(percentageValue) ? 0 : percentageValue; 
                }),
                borderColor: '#00AD3C',
                backgroundColor: 'rgba(0, 173, 60, 0.2)',
                tension: 0.3,  // Slight tension for smoother curves
                fill: true,    // Fill under the line
                pointStyle: 'circle',
                pointRadius: 5,
                pointHoverRadius: 10,
            },
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            title: {
                display: true,
                text: 'Yearly Performance Graph',
            },
            legend: {
                display: true,
            },
            tooltip: {
                callbacks: {
                    label: function (tooltipItem) {
                        const percentageValue = parseFloat(reversedGraphPoints[tooltipItem.dataIndex].percentage);
                        return `Percentage: ${percentageValue.toFixed(2)}%`;
                    },
                },
            },
        },
        scales: {
            x: {
                title: {
                    display: true,
                    text: 'Months',
                },
            },
            y: {
                beginAtZero: true,
                title: {
                    display: true,
                    text: 'Performance (%)',
                },
                ticks: {
                    callback: function (value) {
                        return `${value.toFixed(2)}%`; 
                    },
                },
            },
        },
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="lg"
            fullWidth
            sx={{
                "& .MuiPaper-root": {
                    borderRadius: "20px",
                    padding: '20px 30px',
                    height: "600px",
                    overflow: "scroll",
                    "::-webkit-scrollbar": { width: "0px", height: '0px' },
                },
                "& .MuiBackdrop-root": {
                    backgroundColor: 'rgba(0, 0, 0, 0.8)',
                }
            }}
        >
            <div style={{ width: '100%', height: '400px' }}>
                <Line data={data} options={options} />
            </div>
        </Dialog>
    );
};

export default ShowYearlyGraph;
