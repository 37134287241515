import React from 'react';
import {
  Chart as ChartJS,
  TimeScale,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Chart } from 'react-chartjs-2';
import 'chartjs-adapter-date-fns';
import { Dialog } from '@mui/material';
import moment from 'moment';
import { CandlestickController, CandlestickElement } from 'chartjs-chart-financial';

ChartJS.register(TimeScale, Title, Tooltip, Legend, CandlestickController, CandlestickElement);

const CandleChartModel = ({ performanceGraphDetails, open, onClose }) => {
  const graphPoints = performanceGraphDetails?.graph_points || [];
  console.log('performanceGraphDetails',performanceGraphDetails);
  
  const candleData = graphPoints.map(point => {
    const { date, opening_percentage, closing_percentage } = point;
    const timestamp = date?.seconds ? moment(date.seconds * 1000).valueOf() : null;

    if (timestamp === null) {
      return null; 
    }

    return {
      x: timestamp,
      o: opening_percentage,
      h: Math.max(opening_percentage, closing_percentage),
      l: Math.min(opening_percentage, closing_percentage),
      c: closing_percentage,
    };
  }).filter(dataPoint => dataPoint !== null);

  const data = {
    datasets: [
      {
        label: 'Candle Chart',
        data: candleData,
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
        backgroundColor: (context) => {
          const { dataIndex } = context;
          const currentData = candleData[dataIndex];
          return currentData.c > currentData.o ? 'rgba(75, 192, 192, 1)' : 'rgba(255, 99, 132, 1)';
        },
      },
    ],
  };

  const options = {
    responsive: true,
    scales: {
      x: {
        type: 'time',
        time: {
          unit: 'day', // Display ticks for each day
          tooltipFormat: 'MMM d, yyyy', // Format for tooltips with 'd'
          displayFormats: {
            day: 'MMM d, yyyy', // Format for x-axis labels with 'd'
          },
        },
        title: {
          display: true,
          text: 'Date',
        },
      },
      y: {
        title: {
          display: true,
          text: 'Price',
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: (tooltipItem) => {
            return `O: ${tooltipItem.raw.o.toFixed(2)}, H: ${tooltipItem.raw.h.toFixed(2)}, L: ${tooltipItem.raw.l.toFixed(2)}, C: ${tooltipItem.raw.c.toFixed(2)}`;
          },
        },
      },
    },
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      sx={{
        "& .MuiPaper-root": {
          borderRadius: "20px",
          padding: '20px 30px',
          height: "600px",
          overflow: "scroll",
          "::-webkit-scrollbar": { width: "0px", height: '0px' },
        },
        "& .MuiBackdrop-root": {
          backgroundColor: 'rgba(0, 0, 0, 0.8)',
        }
      }}
    >
      <Chart type="candlestick" data={data} options={options} />
    </Dialog>
  );
};

export default CandleChartModel;
