import { initializeApp } from "firebase/app";
import { getAuth  } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
const firebaseConfig = {
  apiKey: "AIzaSyCO5ZEh8M2sGrZPq9ZTm5T2bxSksml9NQY",
  authDomain: "fidus-finance.firebaseapp.com",
  databaseURL: "https://fidus-finance-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "fidus-finance",
  storageBucket: "fidus-finance.appspot.com",
  messagingSenderId: "528260690137",
  appId: "1:528260690137:web:9eb7e67e9f51b78116cec8",
  measurementId: "G-WNEZPLZWL6"
};
const app = initializeApp(firebaseConfig);
export const AUTH_LOGIN = getAuth(app);
export const DATABASE = getFirestore(app);
export const STORAGE = getStorage(app);