import { Box, Button, Typography } from "@mui/material";
import React, { useRef, useState, useEffect } from "react";
import ReactQuill from "react-quill";
import * as Yup from "yup";
import { useFormik } from "formik";
import Skeleton from "react-loading-skeleton";
import { doc, setDoc, getDoc, updateDoc } from "firebase/firestore";
import "react-quill/dist/quill.snow.css";
import { DATABASE } from "../../../Config/firebase";
import { toast } from "react-toastify";

const AboutUs = () => {
  const ref = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState("");
  const [isUpdating, setIsUpdating] = useState(false);

  useEffect(() => {
    const fetchAboutUs = async () => {
      try {
        setIsLoading(true);
        const docRef = doc(DATABASE, "app_setting", "about-1661896397989");
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setData(docSnap.data().answer);
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error("Error fetching About : ", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchAboutUs();
  }, []);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      about: data || "",
    },
    validationSchema: Yup.object({
      about: Yup.string().required("About is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      setIsUpdating(true);
      try {
        const docRef = doc(DATABASE, "app_setting", "about-1661896397989");

        if (data) {
          await updateDoc(docRef, {
            answer: values.about,
          });
          toast.success("About Updated Successfully")
        } else {
          await setDoc(docRef, {
            answer: values.about,
          });
          toast.success("About Saved Successfully")
        }
        resetForm();
      } catch (error) {
        console.error("Error saving About : ", error);
      } finally {
        setIsUpdating(false);
      }
    },
  });
  return (
    <>
     <Box sx={{ width: "100%", bgcolor: "#fff" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "16px",
          }}
        >
          <Typography sx={{ fontSize: "24px", fontWeight: "500" }}>
            About Us
          </Typography>
        </Box>

        <form
          onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit();
            return false;
          }}
          style={{
            position: "relative",
            backgroundColor: "#fff",
            padding: "15px",
            boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
            borderRadius: "10px",
          }}
        >
          {isLoading ? (
            <Skeleton width={"100%"} height={"230px"} />
          ) : (
            <ReactQuill
              placeholder="Description"
              value={formik.values.about}
              ref={ref}
              modules={{
                toolbar: [
                  [{ header: [1, 2, 3, 4, 5, 6, false] }],
                  ["bold", "italic", "underline"],
                  [{ list: "ordered" }, { list: "bullet" }],
                  [
                    { align: "right" },
                    { align: "center" },
                    { align: null },
                    { align: "justify" },
                  ],
                ],
              }}
              onChange={(content) => {
                content = content.replace(/(^<[^>]*>)([\s]*)/gm, "$1");
                formik.setFieldValue("about", content);
              }}
              className="termsCondtionContent"
            />
          )}

          <Box
            sx={{
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "flex-end",
              marginTop: "24px",
            }}
          >
            <Button
              type="submit"
              variant="contained"
              fullWidth
              disabled={
                !formik?.values?.about
                  ?.replace(/<[^>]+>/g, "")
                  .trim() || isUpdating
              }
              sx={{
                width: "120px",
                height: "48px",
                fontSize: "18px",
                bgcolor: "#6EE498",
                "&:hover": { bgcolor: "#6EE498" },
                borderRadius: "12px",
              }}
            >
              {isUpdating ? "Saving..." : "Save"}
            </Button>
          </Box>
        </form>
      </Box>
    </>
  )
}

export default AboutUs
